<template>
  <div class="paymentplan_info_wrapper">
    <div class="title gap-12 "><span v-if="required" style="color: #f5222d; margin-right: 4px;">*</span>收款计划<a-alert
        v-if="required && showWarning" message="收款计划不能为空" banner closable @close="showWarning = false" />
      <a-alert v-if="paymentDateMsg" :message="paymentDateMsg" banner closable @close="paymentDateMsg = ''" />
    </div>
    <xz-table ref="table" :columns="columns" :data="paymentPlan" :scroll="{ x: 0, y: 540 }" :locale="locale">
      <span slot="ratio" slot-scope="text, record">
        {{ handleRatio(record) }}
      </span>
      <template slot="footer" slot-scope="currentPageData">
        <div>
          <b>收款总金额（元）：</b>{{ Math.round(currentPageData.reduce((pre, cur) => pre + cur.paymentAmount, 0) * 100) / 100 ||
          '' }}
        </div>
      </template>
    </xz-table>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import * as Moment from 'dayjs';

const emptyImg = require('@/assets/empty.svg');

export default {
  name: 'HosipitalAssetPaymentPlanInfo',
  components: {
    xzTable,
  },
  props: {
    providePaymentPlan: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  inject: ['dateConfig'],
  computed: {
    signedDate () {
      return this.dateConfig().value;
    },
    total () {
      const total = {};

      this.providePaymentPlan.forEach(plan => {
        if (!total[plan.name]) {
          total[plan.name] = plan.paymentAmount * 1;
        } else {
          total[plan.name] += plan.paymentAmount * 1;
        }
      });

      return total;
    },
    columns () {
      return [
        {
          title: '计费类目',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };

            if (this.boundaryIndex === -1) {
              if (index === 0) {
                obj.attrs.rowSpan = this.paymentPlan.length;
              } else {
                obj.attrs.rowSpan = 0;
              }
            } else {
              if (index === 0) {
                obj.attrs.rowSpan = this.boundaryIndex;
              } else if (index === this.boundaryIndex) {
                obj.attrs.rowSpan = this.paymentPlan.length - this.boundaryIndex;
              } else {
                obj.attrs.rowSpan = 0;
              }
            }

            return obj;
          },
        },
        {
          title: '期数/次数',
          dataIndex: 'index',
          key: 'index',
          ellipsis: true,
        },
        {
          title: '收款日期',
          dataIndex: 'paymentDate',
          key: 'paymentDate',
          ellipsis: true,
          customRender: (value) => {
            return this.formatDate(value);
          },
        },
        {
          title: '收款金额',
          dataIndex: 'paymentAmount',
          key: 'paymentAmount',
          ellipsis: true,
        },
        {
          title: '本项金额占比',
          dataIndex: 'ratio',
          key: 'ratio',
          ellipsis: true,
          scopedSlots: { customRender: 'ratio' },
        },
      ];
    },
  },
  watch: {
    providePaymentPlan: {
      handler (v) {
        if (v.length > 1) {
          this.boundaryIndex = v.findIndex(i => i.name !== v[0].name);
        }

        const paymentPlan = v.map((i, index) => {
          return {
            ...i,
            index: this.boundaryIndex === -1 || index < this.boundaryIndex ? index + 1 : (index - this.boundaryIndex) + 1,
          };
        });

        this.paymentPlan = paymentPlan;
      },
      immediate: true,
    },
    signedDate: {
      handler (d) {
        this.checkSignedDate(d);
      },
    },
  },
  data () {
    return {
      showWarning: false,
      paymentPlan: [],
      locale: {
        emptyText: () => (<a-empty image={emptyImg} description={'暂无数据，请在履约标的行录入收款计划'}></a-empty>),
      },
      boundaryIndex: -1,
      paymentDateMsg: '',
    };
  },
  methods: {
    checkSignedDate (d) {
      const value = d ? d : Moment(Moment().format('YYYY-MM-DD')).valueOf();

      let needAlert = this.paymentPlan.some(plan => {
        return (
          plan.paymentDate
          && Moment(Moment(plan.paymentDate * 1).format('YYYY-MM-DD')).valueOf()
          < Moment(Moment(value * 1).format('YYYY-MM-DD')).valueOf()
        );
      });

      this.paymentDateMsg = '';

      if (needAlert) {
        this.paymentDateMsg = d ? `存在收款日期小于签订日期${Moment(value * 1).format('YYYY-MM-DD')}, 请修改报价单明细` : `存在收款日期小于当前日期${Moment(value * 1).format('YYYY-MM-DD')}, 请修改报价单明细`;
      }

      return needAlert;
    },
    validate () {
      return new Promise((resolve, reject) => {
        if (!this.paymentPlan.length) {
          this.showWarning = true;
          reject(new Error('fail'));
        } else {
          if (this.checkSignedDate(this.signedDate)) {
            reject(new Error('fail'));
          } else {
            resolve();
          }
        }
      });
    },
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    handleRatio (record) {
      let ratio = record.paymentAmount && this.total[record.name] ? Math.round((record.paymentAmount / this.total[record.name]) * 100 * 100) / 100 : '';

      ratio = ratio ? `${ratio}%` : '';

      return ratio;
    },
  },
};
</script>

<style scoped lang="scss">
.gap-12 {
  margin-bottom: 12px;
}

.paymentplan_info_wrapper {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  .title {
    font-weight: bold;
    display: flex;
    align-items: center;

    .ant-alert {
      flex: 1;
      margin-left: 12px;
    }
  }
}
</style>
