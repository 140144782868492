<template>
  <div class="related_assets">
    <div class="title gap-12">
      <span v-if="required" style="color: #f5222d; margin-right: 4px">*</span>
      履约标的
      <a-alert v-if="required && showWarning" :message="warningMessage" banner closable @close="showWarning = false" />
    </div>
    <a-spin :spinning="loading" tip="数据加载中" size="large">
      <a-form-model ref="relatedAssets" :model="form" :rules="rules">
        <xz-table
          ref="table"
          rowKey="uniqueValue"
          :columns="assetColumns"
          :data="form.assetItemList"
          :scroll="{ x: noAction ? 0 : 1300, y: 600 }"
          :aSlotsKey="['customTitle']"
          :editable="true"
        >
          <template #buttons>
            <div v-if="!noAction">
              <a-button type="primary" icon="plus" :disabled="form.assetItemList.length === 2" @click="newAsset">
                新增行
              </a-button>
              <a-popconfirm placement="leftTop" ok-text="确认" cancel-text="取消" @confirm="handleEmpty">
                <template slot="title">确定清空当前数据？清空后数据将无法恢复</template>
                <a-button>清空</a-button>
              </a-popconfirm>
            </div>
          </template>
          <span slot="serviceLimitYear" slot-scope="text, record, index">
            <a-form-model-item
              v-if="!noAction"
              :prop="'assetItemList.' + index + '.serviceLimitYear'"
              :rules="rules.serviceLimitYear"
            >
              <a-input-number
                v-model="record.serviceLimitYear"
                placeholder="请输入"
                :min="0"
                :step="0.1"
                style="width: 100%"
                :precision="1"
                @change="() => handleTaxAmountChange(record)"
              />
            </a-form-model-item>
            <span v-else>{{ text }}</span>
          </span>
          <span slot="taxPrice" slot-scope="text, record, index">
            <a-form-model-item v-if="!noAction" :prop="'assetItemList.' + index + '.taxPrice'" :rules="rules.taxPrice">
              <a-input-number
                v-model="record.taxPrice"
                placeholder="请输入"
                :min="0"
                :step="0.01"
                style="width: 100%"
                :precision="2"
                @change="() => handleTaxAmountChange(record)"
              />
            </a-form-model-item>
            <span v-else>{{ text }}</span>
          </span>
          <span slot="taxRate" slot-scope="text, record, index">
            <a-form-model-item :prop="'assetItemList.' + index + '.taxRate'" :rules="rules.taxRate">
              <a-select
                v-model="record.taxRate"
                placeholder="请选择"
                allowClear
                style="width: 100%"
                :disabled="!!taxRate"
                @change="() => handleTaxAmountChange(record)"
              >
                <a-select-option v-for="opt in taxRateCache" :key="opt.optionName" :value="opt.optionName">
                  {{ opt.optionName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </span>
          <span slot="planTaxAmount" slot-scope="text, record, index">
            <a-form-model-item
              v-if="!noAction"
              :prop="'assetItemList.' + index + '.planTaxAmount'"
              :rules="setRule(record)"
            >
              <a-input v-model="record.planTaxAmount" disabled placeholder="请输入" style="width: 100%" />
            </a-form-model-item>
            <span v-else>{{ text }}</span>
          </span>
          <span slot="action" slot-scope="text, record, index">
            <a @click="handleQuote(record)">报价单明细</a>
            <a-divider type="vertical" />
            <a-popconfirm placement="leftTop" ok-text="确认" cancel-text="取消" @confirm="handleDelete(index)">
              <template slot="title">确定删除当前数据？删除后数据将无法恢复</template>
              <a>删除</a>
            </a-popconfirm>
          </span>
          <template slot="footer" slot-scope="currentPageData">
            <div>
              <b>含税总金额（元）：</b>
              {{ Math.round(currentPageData.reduce((pre, cur) => pre + cur.taxAmount, 0) * 100) / 100 || '' }}
              <b style="margin-left: 3em">年度服务费（元/年）：</b>
              {{ Math.round(currentPageData.reduce((pre, cur) => pre + cur.taxPrice, 0) * 100) / 100 || '' }}
            </div>
          </template>
        </xz-table>
      </a-form-model>
    </a-spin>
    <a-modal
      v-model="visible"
      title="收款计划明细"
      width="800px"
      :bodyStyle="{ padding: '0 12px' }"
      :maskClosable="false"
      :destroyOnClose="true"
    >
      <HosipitalAssetPaymentPlan
        ref="paymentPlan"
        :providePaymentPlanData.sync="currentRecord.paymentPlanList"
        :total="currentRecord.taxAmount"
        :name="currentRecord.name"
      ></HosipitalAssetPaymentPlan>
      <template slot="footer">
        <a-button @click="handleCancel">取消</a-button>
        <a-button type="primary" @click="handleOk">确认</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import { mapGetters } from 'vuex';
import HosipitalAssetPaymentPlan from './HosipitalAssetPaymentPlan.vue';
import _ from 'lodash';

const columns = [
  {
    title: '结算类项目',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: '服务年限（年）',
    dataIndex: 'serviceLimitYear',
    key: 'serviceLimitYear',
    ellipsis: true,
    scopedSlots: { customRender: 'serviceLimitYear' },
  },
  {
    title: '单价（元/年）',
    dataIndex: 'taxPrice',
    key: 'taxPrice',
    ellipsis: true,
    scopedSlots: { customRender: 'taxPrice' },
  },
  {
    title: '税率',
    dataIndex: 'taxRate',
    key: 'taxRate',
    ellipsis: true,
    scopedSlots: { customRender: 'taxRate' },
  },
  {
    title: '含税金额（元）',
    dataIndex: 'taxAmount',
    key: 'taxAmount',
    ellipsis: true,
  },
  {
    title: '收款计划总额（元）',
    dataIndex: 'planTaxAmount',
    key: 'planTaxAmount',
    ellipsis: true,
    scopedSlots: { customRender: 'planTaxAmount' },
  },
];

export default {
  name: 'HosipitalAssetRelatedAssets',
  components: {
    xzTable,
    HosipitalAssetPaymentPlan,
  },
  props: {
    customerId: {
      type: String,
      default: '',
    },
    noAction: {
      type: Boolean,
      default: true,
    },
    provideAssetItemList: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: true,
    },
    providePaymentPlan: {
      type: Array,
      default: () => [],
    },
    taxRate: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(['taxRateCache']),
    assetColumns () {
      return this.noAction
        ? columns
        : [
          ...columns,
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: 160,
            scopedSlots: { customRender: 'action' },
          },
        ];
    },
  },
  watch: {
    customerId () {
      // 客户改变，清空已选设备列表
      if (this.form.assetItemList.length) {
        if (this.$local.get('changeCustomer') !== 'yes') {
          this.$message({
            message: '客户抬头已变化，请重新填写履约标的。',
            type: 'warning',
          });
          this.form.assetItemList = [];
        } else {
          this.$local.remove('changeCustomer');
        }
      } else {
        this.$local.remove('changeCustomer');
      }
    },
    provideAssetItemList: {
      handler (v) {
        this.form.assetItemList = [...v];
      },
      immediate: true,
    },
  },
  data () {
    return {
      showWarning: false,
      visible: false,
      form: {
        assetItemList: [],
      },
      currentRecord: {},
      rules: {
        serviceLimitYear: [{ required: true, message: '请输入', trigger: 'blur' }],
        taxPrice: [{ required: true, message: '请输入', trigger: 'blur' }],
        taxRate: [{ required: true, message: '请输入', trigger: 'change' }],
      },
      loading: false,
      warningMessage: '履约标的不能为空',
    };
  },
  methods: {
    validatePayment (rule, value, callback) {
      if ((value && value !== rule.taxAmount) || (!value && rule.taxAmount)) {
        this.warningMessage = '收款计划总额须与含税金额相等';
        this.showWarning = true;
        return callback(new Error(' '));
      }

      callback();
    },
    setRule (row) {
      return [
        { taxAmount: row.taxAmount, validator: this.validatePayment, trigger: 'change' },
      ];
    },
    handleQuote (record) {
      this.currentRecord = record;

      this.visible = true;
    },
    back () {
      this.$router.back();
    },
    newAsset () {
      this.form.assetItemList.push({
        name: this.form.assetItemList.some(asset => asset.name === '维修服务') ? '技术服务' : '维修服务',
        serviceLimitYear: undefined,
        taxRate: this.taxRate,
        taxPrice: undefined,
        taxAmount: undefined,
        planTaxAmount: undefined,
        paymentPlanList: [],
        uniqueValue: _.uniqueId(),
      });

      if (this.form.assetItemList[0].name === '技术服务') {
        this.form.assetItemList.reverse();
      }
    },
    handleTaxAmountChange (row, isGeneralInvoice = false) {
      if (row) {
        const taxAmount
          = row.serviceLimitYear && row.taxPrice ? Math.round(row.taxPrice * 100 * row.serviceLimitYear) / 100 : '';

        this.$set(row, 'taxAmount', taxAmount);
      }

      this.$emit('handleTotalAmount', this.form.assetItemList, isGeneralInvoice);
    },
    handleDelete (index) {
      this.form.assetItemList.splice(index, 1);

      this.form.assetItemList.map((asset, pIndex) => {
        asset.paymentPlanList.forEach((bill, cIndex) => {
          bill.index = `${pIndex + 1}-${cIndex + 1}`;
        });
      });

      this.$emit('update:providePaymentPlan', this.form.assetItemList.map(v => v.paymentPlanList).flat());

      this.$emit('handleTotalAmount', this.form.assetItemList, false);
    },
    handleEmpty () {
      this.form.assetItemList = [];

      this.$emit('update:providePaymentPlan', []);

      this.$emit('handleTotalAmount', this.form.assetItemList, false);
    },
    handleCancel () {
      this.visible = false;
    },
    async handleOk () {
      await this.$refs.paymentPlan.validate();

      this.currentRecord.planTaxAmount = (this.currentRecord.paymentPlanList || []).reduce((pre, cur) => {
        return pre + cur.paymentAmount * 100;
      }, 0);
      this.currentRecord.planTaxAmount = this.currentRecord.planTaxAmount
        ? Math.round(this.currentRecord.planTaxAmount) / 100
        : '';

      this.$emit('update:providePaymentPlan', this.form.assetItemList.map(v => v.paymentPlanList).flat());

      this.visible = false;
    },
    validate () {
      return new Promise((resolve, reject) => {
        if (!this.form.assetItemList.length) {
          this.warningMessage = '履约标的不能为空';
          this.showWarning = true;

          reject(new Error('fail'));
        } else {
          this.$refs.relatedAssets.validate(async valid => {
            if (valid) {
              this.$emit('update:provideAssetItemList', this.form.assetItemList);
              resolve(valid);
            } else {
              reject(new Error('fail'));
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.gap-12 {
  margin-bottom: 12px;
}

.related_assets {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  .title {
    font-weight: bold;
    display: flex;
    align-items: center;

    .ant-alert {
      flex: 1;
      margin-left: 12px;
    }
  }

  .extra-input {
    display: flex;
    align-items: flex-start;
    padding: 24px 0 12px 0;

    span {
      width: 9em;
      flex-shrink: 0;
    }

    .ant-input {
      flex: 1;
    }
  }

  .disabledA {
    color: gray;
    cursor: default;
  }
}
</style>

