import Enum from '@/utils/Enum';

const SaleContractTemplateTypes = {
  'MAINTENANCE': [
    {
      value: 'MAINTENANCE',
      name: '保养服务',
    },
  ],
  'WARRANTY': [
    {
      value: 'WARRANTY',
      name: '保修服务',
    },
  ],
  'RELOCATION': [
    {
      value: 'RELOCATION',
      name: '移机装机服务',
    },
  ],
  'SINGLE_REPAIR': [
    {
      value: 'SINGLE_REPAIR',
      name: '单次维修服务协议',
    },
    {
      value: 'QUOTATION_LETTER',
      name: '维修服务报价函',
    },
  ],
  'TECHNOLOGY_SERVICE': [
    {
      value: 'TECHNOLOGY_SERVICE',
      name: '技术服务',
    },
  ],
  'ASSET_MANAGEMENT': [
    {
      value: 'HOSPITAL_ASSET_MANAGEMENT',
      name: '医院资管',
    },
  ],
};

const flatTypes = [
  {
    value: 'MAINTENANCE',
    name: '保养服务',
  },
  {
    value: 'WARRANTY',
    name: '保修服务',
  },
  {
    value: 'RELOCATION',
    name: '移机装机服务',
  },
  {
    value: 'SINGLE_REPAIR',
    name: '单次维修服务协议',
  },
  {
    value: 'QUOTATION_LETTER',
    name: '维修服务报价函',
  },
  {
    value: 'TECHNOLOGY_SERVICE',
    name: '技术服务',
  },
  {
    value: 'HOSPITAL_ASSET_MANAGEMENT',
    name: '医院资管',
  },
];

export default new Enum(flatTypes);

export { SaleContractTemplateTypes };
