<template>
  <div class="new_contract_wrapper">
    <div class="header">
      <div style="display: flex; align-items: center;">
        <a-button icon="arrow-left" size="small" @click="back" />
        <span class="page_type">新增</span>
        <div class="breadcrumb_wrapper">
          <div class="breadcrumb">
            <span>{{ BusinessTypeModelMap[businessType] }}</span>
            <span style="margin: 0 3px">/</span>
            <span>{{ SaleContractTemplateMap[templateType] }}</span>
            <a-icon type="edit" theme="twoTone" @click="visible = true" />
          </div>
        </div>
      </div>
      <div>
        <template v-if="!isDirectlySalesCompany">
          <span class="icon-wrapper active"><a-icon type="file-done" /></span>
          <span class="name">加盟销售合同</span>
          &ensp;
          <a-icon type="arrow-right" style="color: #d7dbe0" />
          &ensp;
          <span :class="['icon-wrapper', step === 'second' ? 'active' : '']"><a-icon type="block" /></span>
          <span class="name">直营销售合同</span>
        </template>
      </div>
    </div>
    <div class="content">
      <component :is="templates[this.templateType]" :id="templateType === $route.query.templateType ? id : undefined"
        :contractType="type" :step.sync="step" :businessType="businessType" :templateType="templateType"
        :userInfo="userInfo" :bankInfo="bankInfo"></component>
    </div>
    <a-modal v-model="visible" title="请选择业务类型及合同模板" :closable="false" @cancel="visible = false" @ok="handleOk">
      <a-form-model ref="form" :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 12 }">
        <a-form-model-item label="业务类型" prop="businessType">
          <a-select v-model="form.businessType" placeholder="请选择" @select="handleBusinessTypeChange">
            <a-select-option v-for="opt in BusinessTypeModelEnum" :key="opt.value" :value="opt.value">
              {{ opt.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="合同模板" prop="templateType">
          <a-select v-model="form.templateType" placeholder="请选择">
            <a-select-option v-for="opt in SaleContractTemplateTypes[form.businessType]" :key="opt.value"
              :value="opt.value">
              {{ opt.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import BusinessTypeModelMap, { BusinessTypeModelEnum } from '@/enums/BusinessTypeEnum';
import SaleContractTemplateMap, { SaleContractTemplateTypes } from '@/enums/SaleContractTemplateTypes';
import MaintenanceTemplate from './components/MaintenanceTemplate.vue';
import WarrantyTemplate from './components/WarrantyTemplate.vue';
import RelocationTemplate from './components/RelocationTemplate.vue';
import QuotationLetter from './components/QuotationLetter.vue';
import SingleRepairTemplate from './components/SingleRepairTemplate.vue';
import TechnologyTemplate from './components/TechnologyTemplate.vue';
import HospitalAssetManagement from './components/HospitalAssetManagementTemplate.vue';
import { mapActions } from 'vuex';
import { tenantDetail } from '@/api/contract';
import { templateMixin } from '../mixins/directlySaleCompanyMixin';

export default {
  name: 'NewSaleContract',
  props: {
    id: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: '',
    },
  },
  mixins: [templateMixin],
  data () {
    return {
      templates: {
        MAINTENANCE: MaintenanceTemplate,
        WARRANTY: WarrantyTemplate,
        RELOCATION: RelocationTemplate,
        SINGLE_REPAIR: SingleRepairTemplate,
        QUOTATION_LETTER: QuotationLetter,
        TECHNOLOGY_SERVICE: TechnologyTemplate,
        HOSPITAL_ASSET_MANAGEMENT: HospitalAssetManagement,
      },
      BusinessTypeModelEnum,
      BusinessTypeModelMap,
      SaleContractTemplateTypes,
      SaleContractTemplateMap,
      businessType: 'MAINTENANCE',
      templateType: SaleContractTemplateTypes['MAINTENANCE'][0].value,
      form: {
        businessType: 'MAINTENANCE',
        templateType: SaleContractTemplateTypes['MAINTENANCE'][0].value,
      },
      visible: false,
      step: 'first',
      userInfo: {},
      bankInfo: [],
      activeId: undefined,
    };
  },
  methods: {
    ...mapActions([
      'getTaxRate',
      'getSupplier',
      'getAnnexName',
      'getMeasureUnit',
      'getJifeiLeimu',
      'getQualityAssType',
      'getRate',
    ]),
    back () {
      this.$router.back();
    },
    handleBusinessTypeChange () {
      this.form.templateType = SaleContractTemplateTypes[this.form.businessType][0].value;
    },
    handleOk () {
      if (this.businessType !== this.form.businessType) {
        this.businessType = this.form.businessType;
      }

      if (this.templateType !== this.form.templateType) {
        this.templateType = this.form.templateType;
      }

      this.step = 'first';
      this.visible = false;
    },
    getTenantDetail () {
      tenantDetail({ id: this.userInfo.tenantId }).then(({ body }) => {
        this.getSupplier({ id: this.isDirectlySalesCompany ? (body?.accountingUnitId || undefined) : undefined });

        const info = (body.tenantCertificateInfoResDTOs || []).find(v => v.type * 1 === 14);

        if (info) {
          this.bankInfo = [
            {
              id: info.id,
              bankAccount: info.bankAccount,
              bankOfDeposit: info.openingBankName,
            },
          ];
        }
      });
    },
  },
  created () {
    if (this.id) {
      this.businessType = this.$route.query.businessType;
      this.templateType = this.$route.query.templateType;
      this.form.businessType = this.$route.query.businessType;
      this.form.templateType = this.$route.query.templateType;
    }

    this.visible = !this.id;

    this.userInfo = this.$local.get('userInfo') ? JSON.parse(this.$local.get('userInfo')) : {};

    this.getTaxRate();
    this.getAnnexName();
    this.getMeasureUnit();
    this.getJifeiLeimu();
    this.getQualityAssType();
    this.getRate();
  },
  mounted () {
    this.getTenantDetail();
  },
};
</script>

<style scoped lang="scss">
.new_contract_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 52px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    .page_type {
      font-size: 16px;
      font-weight: bold;
      margin-left: 1em;
    }

    .icon-wrapper {
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      font-size: 16px;
      padding: 0 7px;
      color: #fff;
      background-color: #d7dbe0;
      margin-right: 0.5em;

      &.active {
        background-color: #237ffa;
      }
    }

    .name {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .breadcrumb_wrapper {
    .breadcrumb {
      padding: 12px;
      background-color: #fff;
      font-weight: bold;

      i {
        margin-left: 8px;
        cursor: pointer;

        &:hover {
          background-color: rgba(24, 144, 255, 0.2);
        }
      }
    }
  }

  .content {
    flex: 1;
    padding: 16px 0 0;
    overflow: hidden;
  }
}
</style>
