<template>
  <div class="maintenance_template_wrapper">
    <div class="content">
      <div v-show="step === 'first'" class="step">
        <ContractBaseInfo
          ref="contractBaseInfo"
          :provideForm.sync="saleContract.form"
          :businessType="businessType"
          :customer.sync="customer"
          :customerId="customerId"
          :templateType="templateType"
          :isDirectlySalesCompany="isDirectlySalesCompany"
          @updateRate="updateRelatedAssetsRate"
        />
        <HosipitalAssetRelatedAssets
          ref="relatedAssets"
          :customerId="customer.id"
          :provideAssetItemList.sync="saleContract.assetItemList"
          :providePaymentPlan.sync="provideSalePaymentPlan"
          :noAction="false"
          :taxRate="saleContract.form.tariff"
          @handleTotalAmount="handleTotalAmount"
        ></HosipitalAssetRelatedAssets>
        <HosipitalAssetPaymentPlanInfo
          ref="paymentPlanInfo"
          :providePaymentPlan="provideSalePaymentPlan"
        ></HosipitalAssetPaymentPlanInfo>
        <HospitalAssetManagementTerms
          ref="hospitalAssetManagementTerms"
          :provideClause.sync="saleContract.clause"
          :noAction="false"
        ></HospitalAssetManagementTerms>
        <AttachmentInfo
          ref="attachmentInfo"
          :provideAnnexList.sync="saleContract.annexList"
          :noAction="false"
          :required="true"
        ></AttachmentInfo>
        <div class="common text">
          <span class="title">合同备注</span>
          <a-input v-model="saleContract.remark" placeholder="请输入" allowClear style="width: 100%"></a-input>
        </div>
      </div>
      <div v-show="step === 'second'" class="step">
        <div class="common">
          <div class="title gap-12">合同基础信息</div>
          <a-form-model
            ref="purchContractForm"
            :model="purchContract.form"
            :rules="purchContractRules"
            :wrapper-col="wrapperCol"
          >
            <a-row>
              <a-col :span="8">
                <a-form-model-item label="发票类型" prop="invoiceType">
                  <a-select
                    v-model="purchContract.form.invoiceType"
                    placeholder="请选择"
                    allowClear
                    @change="handleInvoiceChange"
                  >
                    <a-select-option v-for="opt in InvoiceTypeEnum" :key="opt.value" :value="opt.value">
                      {{ opt.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item ref="taxRate" label="税率" prop="taxRate">
                  <a-select
                    v-model="purchContract.form.taxRate"
                    placeholder="请选择"
                    allowClear
                    @change="updateRelatedPurchPaymentPlansRate"
                  >
                    <a-select-option v-for="opt in taxRateCache" :key="opt.optionName" :value="opt.optionName">
                      {{ opt.optionName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item ref="totalAmount" label="合同金额" prop="totalAmount">
                  {{ purchContract.form.totalAmount }}
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="16">
                <a-form-model-item ref="bankAccounts" label="收款账号" prop="bankAccounts">
                  <a-select v-model="purchContract.form.bankAccounts" placeholder="请选择" allowClear>
                    <a-select-option
                      v-for="opt in bankInfo"
                      :key="opt.id"
                      :value="`${opt.bankOfDeposit}_${opt.bankAccount}`"
                    >
                      {{ `${opt.bankOfDeposit}_${opt.bankAccount}` }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
          <a-descriptions :style="{ padding: '0 16px' }">
            <a-descriptions-item label="客户抬头">
              {{ this.purchContract.form.accountingUnit }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商抬头">
              {{ this.purchContract.form.supName }}
            </a-descriptions-item>
            <a-descriptions-item label="最终用户">
              {{ this.purchContract.form.finalCustomerName }}
            </a-descriptions-item>
            <a-descriptions-item label="生效起止日">
              {{ formatDate(this.purchContract.form.effectiveDate) }} ~
              {{ formatDate(this.purchContract.form.contractDate) }}
            </a-descriptions-item>
            <a-descriptions-item label="业务员">
              {{ this.purchContract.form.salesmanName }}
            </a-descriptions-item>
            <a-descriptions-item label="签订日期">
              {{ formatDate(this.purchContract.form.signedDate) }}
            </a-descriptions-item>
            <a-descriptions-item label="结算方式">
              {{ PurchasePaymentMethodEnumMap[this.purchContract.form.payWay] }}
            </a-descriptions-item>
            <a-descriptions-item label="合同主题">
              {{ this.purchContract.form.name }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <HosipitalAssetRelatedAssets
          ref="purchRelatedAssets"
          :provideAssetItemList="purchContract.assetItemList"
          :taxRate="purchContract.form.taxRate"
          @handleTotalAmount="handlePurchTotalAmount"
        ></HosipitalAssetRelatedAssets>
        <HosipitalAssetPaymentPlanInfo :providePaymentPlan="providePurchPaymentPlan"></HosipitalAssetPaymentPlanInfo>
        <HospitalAssetManagementTerms :provideClause.sync="purchContract.clause"></HospitalAssetManagementTerms>
        <AttachmentInfo :provideAnnexList="purchContract.annexList"></AttachmentInfo>
        <div class="common text">
          <span class="title">合同备注</span>
          <a-input v-model="purchContract.remark" placeholder="请输入" allowClear style="width: 100%"></a-input>
        </div>
      </div>
    </div>
    <div class="footer">
      <a-button @click="back">取消</a-button>
      <a-button v-if="isDirectlySalesCompany || step === 'second'" @click="onSubmit(true)">保存</a-button>
      <a-button @click="handleViewContract">合同预览</a-button>
      <a-button v-if="!isDirectlySalesCompany && step === 'first'" type="primary" @click="handleStep('second')">下一步</a-button>
      <a-button v-if="!isDirectlySalesCompany && step === 'second'" @click="handleStep('first')">上一步</a-button>
      <a-button v-if="isDirectlySalesCompany || step === 'second'" type="primary" @click="onSubmit(false)">提交</a-button>
    </div>
    <a-modal v-model="visible" title="合同预览" :width="900" :bodyStyle="{ padding: '0 12px' }" centered>
      <HospitalAssetContract :contractData="contractData"></HospitalAssetContract>
      <template slot="footer">
        <span></span>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { InvoiceTypeEnum } from '@/enums/InvoiceTypeEnum';
import PurchasePaymentMethodEnumMap from '@/enums/PurchasePaymentMethodEnum';
import { newSaleContract, saleContractDetail, purchaseContractDetail, updateContract } from '@/api/contract';
import * as Moment from 'dayjs';
import { mapGetters } from 'vuex';
import HospitalAssetContract from '@/components/fileTemplates/hospitalAssetContract.vue';
import ContractBaseInfo from './ContractBaseInfo.vue';
import AttachmentInfo from './AttachmentInfo.vue';
import HosipitalAssetRelatedAssets from './HosipitalAssetRelatedAssets.vue';
import HosipitalAssetPaymentPlanInfo from './HosipitalAssetPaymentPlanInfo.vue';
import { numberTranToUpperCN, numberTranToCN } from '@/utils/chinesePrice';
import HospitalAssetManagementTerms from './HospitalAssetManagementTerms.vue';
import { templateMixin } from '../../mixins/directlySaleCompanyMixin';

import _ from 'lodash';

const THREE_DAYS_MILLSECONDS = 3 * 24 * 3600 * 1000;
const YEAR_MILLSECONDS = 24 * 3600 * 1000 * 365;

export default {
  name: 'HospitalAssetManagementTemplate',
  components: {
    HospitalAssetContract,
    ContractBaseInfo,
    AttachmentInfo,
    HosipitalAssetRelatedAssets,
    HosipitalAssetPaymentPlanInfo,
    HospitalAssetManagementTerms,
  },
  mixins: [templateMixin],
  props: {
    businessType: {
      type: String,
      required: true,
    },
    templateType: {
      type: String,
      required: true,
    },
    step: {
      type: String,
      default: 'first',
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
    bankInfo: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['taxRateCache', 'supplierCache', 'rateCache']),
  },
  provide () {
    return {
      dateConfig: () => ({
        value: this.saleContract.form.signedDate,
      }),
    };
  },
  data () {
    return {
      PurchasePaymentMethodEnumMap,
      provideSalePaymentPlan: [],
      providePurchPaymentPlan: [],
      visible: false,
      InvoiceTypeEnum,
      wrapperCol: { span: 16 },
      saleContract: {
        form: {
          customerHead: undefined,
          accountingUnit: undefined,
          finalCustomerName: undefined,
          invoiceType: 'DEDICATED',
          tariff: '13%',
          totalAmount: undefined,
          saleContractDate: undefined,
          salesmanName: this.userInfo.name,
          signedDate: undefined,
          paymentMethod: 2, // 背靠背
          bankAccounts: undefined,
          name: undefined,
        },
        assetItemList: [],
        annexList: [],
        clause: {},
        remark: undefined,
      },
      purchContract: {
        form: {
          invoiceType: 'DEDICATED',
          taxRate: undefined,
          totalAmount: undefined,
          bankAccounts: undefined,
          businessType: undefined,
          templateType: undefined,
          accountingUnit: undefined,
          supplierId: undefined,
          supName: undefined,
          finalCustomerName: undefined,
          effectiveDate: undefined,
          contractDate: undefined,
          salesmanName: undefined,
          signedDate: undefined,
          payWay: undefined,
          name: undefined,
        },
        assetItemList: [],
        annexList: [],
        clause: {},
        remark: undefined,
      },
      purchContractRules: {
        invoiceType: [{ required: true, message: '请选择发票类型', trigger: 'change' }],
        totalAmount: [{ required: true, message: '请输入合同金额', trigger: 'blur' }],
        bankAccounts: [{ required: true, message: '请输入收款账号', trigger: 'blur' }],
      },
      contractData: {
        partA: {},
        partB: {},
        effectiveDate: ['', '', ''],
        terminalDate: ['', '', ''],
        paymentList: [],
        paymentDays: '',
        additionalDeviceSystemServe: '',
        totalAmountPerYear: '',
        chineseTotalAmountPerYear: '',
        totalAmount: '',
        taxRate: '',
        chineseTotalAmount: '',
        bankName: '',
        bankAccount: '',
        invoiceTypeName: '',
        attachmentList: [],
        billList: [],
      },
      customer: {},
      customerId: '',
    };
  },
  methods: {
    handlePurchTotalAmount (list, isGeneralInvoice) {
      this.purchContract.form.totalAmount = 0;

      list.forEach(v => {
        const tariff = v.originTaxRate ? v.originTaxRate.replace('%', '') * 1 : 0; // 销售税率
        const taxRate = isGeneralInvoice ? 0 : v.taxRate ? v.taxRate.replace('%', '') * 1 : 0; // 采购税率
        const rate = (100 - tariff) / (100 - taxRate);

        v.taxPrice = Math.round(v.originTaxPrice * (1 - this.rateCache) * rate * 100) / 100;
        v.taxAmount = Math.round(v.taxPrice * v.serviceLimitYear * 100) / 100;
        v.planTaxAmount = v.taxAmount;

        this.purchContract.form.totalAmount += v.taxAmount * 100;

        const lastIndex = v.paymentPlanList.length - 1;
        let val = v.taxAmount;

        v.paymentPlanList.forEach((plan, index) => {
          if (index === lastIndex) {
            plan.paymentAmount = Math.round(val * 100) / 100;
          } else {
            plan.paymentAmount = Math.round(plan.originPaymentAmount * (1 - this.rateCache) * rate * 100) / 100;

            val -= plan.paymentAmount;
          }
        });
      });

      this.purchContract.form.totalAmount = this.purchContract.form.totalAmount
        ? this.purchContract.form.totalAmount / 100
        : undefined;

      this.providePurchPaymentPlan = this.purchContract.assetItemList.map(v => v.paymentPlanList).flat();
    },
    handleTotalAmount (list) {
      const totalAmount = list.reduce((pre, cur) => {
        return pre + (cur.taxAmount ? cur.taxAmount * 1 : 0) * 100;
      }, 0);

      this.$refs.contractBaseInfo.form.totalAmount = totalAmount / 100;
    },
    handleInvoiceChange (v) {
      if (v.includes('GENERAL')) {
        // 发票为普通类型
        this.$refs.purchRelatedAssets.handleTaxAmountChange(null, true);
      } else {
        this.$refs.purchRelatedAssets.handleTaxAmountChange();
      }
    },
    updateRelatedAssetsRate (taxRate) {
      this.saleContract.form.tariff = taxRate;

      this.$refs.relatedAssets.form.assetItemList.forEach(d => {
        d.taxRate = taxRate;
      });
    },
    updateRelatedPurchPaymentPlansRate (taxRate) {
      this.$refs.purchRelatedAssets.form.assetItemList.forEach(d => {
        d.taxRate = taxRate;
      });

      this.$refs.purchRelatedAssets.handleTaxAmountChange(
        null,
        this.purchContract.form.invoiceType.includes('GENERAL'),
      );
    },
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    back (refresh = false) {
      this.$tabs.close({
        to: '/salesmanagement/salescontracts/list',
        refresh,
      });
    },
    getContractLimt (date) {
      let ret = '';

      if (date && date[0] && date[1]) {
        ret = Math.round(((date[1] - date[0]) / YEAR_MILLSECONDS) * 10) / 10;
      }

      return ret;
    },
    getAttachmentName (businessType, attachmentList) {
      let name = '';

      if (businessType && attachmentList?.length) {
        const o = attachmentList.find(a => a.businessType === businessType);

        if (o) {
          name = o.businessTypeDesc;
        }
      }

      return name;
    },
    setSaleContractContent () {
      const yearServeFee
        = parseInt(
          Math.round(this.$refs.relatedAssets.form.assetItemList.reduce((pre, cur) => pre + cur.taxPrice, 0) * 100),
        ) / 100 || '';
      const yearServeFeeCHN = yearServeFee ? numberTranToUpperCN(yearServeFee + '') : '';
      const totalFee = Math.round(this.$refs.relatedAssets.form.assetItemList.reduce((pre, cur) => pre + cur.taxAmount, 0) * 100) / 100 || '';
      const totalFeeCHN = totalFee ? numberTranToUpperCN(totalFee + '') : '';
      const repairService = this.$refs.relatedAssets.form.assetItemList.filter(asset => asset.name === '维修服务');
      const repairServiceFee = repairService?.[0]?.taxAmount || '';
      const technologyService = this.$refs.relatedAssets.form.assetItemList.filter(asset => asset.name === '技术服务');
      const technologyServiceFee = technologyService?.[0]?.taxAmount || '';

      this.contractData = {
        partA: {
          name: this.$refs.contractBaseInfo.form.customerHead || '',
          legalPerson: '',
          address: `${this.customer.provinceName || ''}${this.customer.cityName || ''}${this.customer.areaName || ''}${
            this.customer.address || ''
          }`,
          postCode: '',
          telephone: '',
          fax: '',
        },
        partB: {
          name: this.$refs.contractBaseInfo.form.accountingUnit || '',
          legalPerson: this.supplierCache.legalPerson || '',
          address: `${this.supplierCache.provinceName || ''}${this.supplierCache.cityName || ''}${
            this.supplierCache.areaName || ''
          }${this.supplierCache.address || ''}`,
          postCode: this.supplierCache.postcode || '',
          telephone: this.supplierCache.telePhone || '',
          fax: this.supplierCache.fax || '',
        },
        effectiveDate: this.$refs.contractBaseInfo.form.saleContractDate?.[0]
          ? this.formatDate(this.$refs.contractBaseInfo.form.saleContractDate?.[0], 'YYYY-MM-DD', '').split('-')
          : ['', '', ''],
        terminalDate: this.$refs.contractBaseInfo.form.saleContractDate?.[1]
          ? this.formatDate(this.$refs.contractBaseInfo.form.saleContractDate?.[1], 'YYYY-MM-DD', '').split('-')
          : ['', '', ''],
        contractLimit: this.getContractLimt(this.$refs.contractBaseInfo.form.saleContractDate),
        bankName: this.$refs.contractBaseInfo.form.bankAccounts
          ? this.$refs.contractBaseInfo.form.bankAccounts.split('_')[0]
          : '',
        bankAccount: this.$refs.contractBaseInfo.form.bankAccounts
          ? this.$refs.contractBaseInfo.form.bankAccounts.split('_')[1]
          : '',
        attachmentList: this.$refs.attachmentInfo.form.annexList,
        clause: this.$refs.hospitalAssetManagementTerms.clause,
        unconsumableAttachment: this.getAttachmentName('8s3dDO', this.$refs.attachmentInfo.form.annexList), // 消费品清单附件 8s3dDO
        assetDevicesAttachment: this.getAttachmentName('hCZA31', this.$refs.attachmentInfo.form.annexList), // 资产设备清单附件 hCZA31
        siteAttachment: this.getAttachmentName('VeSw5q', this.$refs.attachmentInfo.form.annexList), // 场地附件 VeSw5q
        yearServeFee, // 年度服务费
        yearServeFeeCHN,
        totalFee, // 合同总价款
        totalFeeCHN,
        repairService: repairService.length ? repairService[0] : {},
        repairServicePaymentPlan: repairService.length ? (repairService[0].paymentPlanList || []).map((plan, index) => {
          return {
            ...plan,
            index: numberTranToCN(index + 1),
            payDays: plan.paymentDate ? this.formatDate(plan.paymentDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
            ratio: repairServiceFee && plan.paymentAmount ? Math.round(plan.paymentAmount / repairServiceFee * 10000) / 100 : '',
          };
        }) : [],
        technologyService: technologyService.length ? technologyService[0] : {},
        technologyServicePaymentPlan: technologyService.length ? (technologyService[0].paymentPlanList || []).map((plan, index) => {
          return {
            ...plan,
            index: numberTranToCN(index + 1),
            payDays: plan.paymentDate ? this.formatDate(plan.paymentDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
            ratio: technologyServiceFee && plan.paymentAmount ? Math.round(plan.paymentAmount / technologyServiceFee * 10000) / 100 : '',
          };
        }) : [],
      };
    },
    setPurchaseContractContent () {
      const yearServeFee
        = parseInt(
          Math.round(this.purchContract.assetItemList.reduce((pre, cur) => pre + cur.taxPrice, 0) * 100),
        ) / 100 || '';
      const yearServeFeeCHN = yearServeFee ? numberTranToUpperCN(yearServeFee + '') : '';
      const totalFee = Math.round(this.purchContract.assetItemList.reduce((pre, cur) => pre + cur.taxAmount, 0) * 100) / 100 || '';
      const totalFeeCHN = totalFee ? numberTranToUpperCN(totalFee + '') : '';
      const repairService = this.purchContract.assetItemList.filter(asset => asset.name === '维修服务');
      const repairServiceFee = repairService?.[0]?.taxAmount || '';
      const technologyService = this.purchContract.assetItemList.filter(asset => asset.name === '技术服务');
      const technologyServiceFee = technologyService?.[0]?.taxAmount || '';

      this.contractData = {
        partA: {
          name: this.purchContract.form.accountingUnit || '',
          legalPerson: this.supplierCache.legalPerson || '',
          address: `${this.supplierCache.provinceName || ''}${this.supplierCache.cityName || ''}${
            this.supplierCache.areaName || ''
          }${this.supplierCache.address || ''}`,
          postCode: this.supplierCache.postcode || '',
          telephone: this.supplierCache.telePhone || '',
          fax: this.supplierCache.fax || '',
        },
        partB: {
          name: this.purchContract.form.supName || '',
          legalPerson: '',
          address: '',
          postCode: '',
          telephone: '',
          fax: '',
        },
        effectiveDate: this.purchContract.form.effectiveDate
          ? this.formatDate(this.purchContract.form.effectiveDate, 'YYYY-MM-DD', '').split('-')
          : ['', '', ''],
        terminalDate: this.purchContract.form.contractDate
          ? this.formatDate(this.purchContract.form.contractDate, 'YYYY-MM-DD', '').split('-')
          : ['', '', ''],
        contractLimit: this.getContractLimt([
          this.purchContract.form.effectiveDate,
          this.purchContract.form.contractDate,
        ]),
        bankName: this.purchContract.form.bankAccounts ? this.purchContract.form.bankAccounts.split('_')[0] : '',
        bankAccount: this.purchContract.form.bankAccounts ? this.purchContract.form.bankAccounts.split('_')[1] : '',
        attachmentList: this.purchContract.annexList,
        clause: this.purchContract.clause,
        unconsumableAttachment: this.getAttachmentName('8s3dDO', this.purchContract.annexList), // 消费品清单附件 8s3dDO
        assetDevicesAttachment: this.getAttachmentName('hCZA31', this.purchContract.annexList), // 资产设备清单附件 hCZA31
        siteAttachment: this.getAttachmentName('VeSw5q', this.purchContract.annexList), // 场地附件 VeSw5q
        yearServeFee, // 年度服务费
        yearServeFeeCHN,
        totalFee, // 合同总价款
        totalFeeCHN,
        repairService: repairService.length ? repairService[0] : {},
        repairServicePaymentPlan: repairService.length ? (repairService[0].paymentPlanList || []).map((plan, index) => {
          return {
            ...plan,
            index: numberTranToCN(index + 1),
            payDays: plan.paymentDate ? this.formatDate(plan.paymentDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
            ratio: repairServiceFee && plan.paymentAmount ? Math.round(plan.paymentAmount / repairServiceFee * 10000) / 100 : '',
          };
        }) : [],
        technologyService: technologyService.length ? technologyService[0] : {},
        technologyServicePaymentPlan: technologyService.length ? (technologyService[0].paymentPlanList || []).map((plan, index) => {
          return {
            ...plan,
            index: numberTranToCN(index + 1),
            payDays: plan.paymentDate ? this.formatDate(plan.paymentDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
            ratio: technologyServiceFee && plan.paymentAmount ? Math.round(plan.paymentAmount / technologyServiceFee * 10000) / 100 : '',
          };
        }) : [],
      };
    },
    handleViewContract () {
      if (this.step === 'first') {
        this.setSaleContractContent();
      } else {
        this.setPurchaseContractContent();
      }
      this.visible = true;
    },
    async handleStep (step) {
      if (step === 'second') {
        await Promise.all([
          this.$refs.contractBaseInfo.validate(),
          this.$refs.relatedAssets.validate(),
          this.$refs.hospitalAssetManagementTerms.validate(),
          this.$refs.attachmentInfo.validate(),
          this.$refs.paymentPlanInfo.validate(),
        ]);

        this.purchContract.form.accountingUnit = this.saleContract.form.accountingUnit;
        this.purchContract.form.supName = this.userInfo.tenantName;
        this.purchContract.form.finalCustomerName = this.saleContract.form.accountingUnit;
        this.purchContract.form.effectiveDate = this.saleContract.form.saleContractDate
          ? this.saleContract.form.saleContractDate[0]
          : undefined;
        this.purchContract.form.contractDate = this.saleContract.form.saleContractDate
          ? this.saleContract.form.saleContractDate[1]
          : undefined;
        this.purchContract.form.salesmanName = this.saleContract.form.salesmanName;
        this.purchContract.form.signedDate = this.saleContract.form.signedDate;
        this.purchContract.form.payWay = this.saleContract.form.paymentMethod * 1 + 1;
        this.purchContract.form.name = this.saleContract.form.name;
        this.purchContract.form.taxRate = undefined;

        if (
          !this.purchContract.form.bankAccounts
          && this.bankInfo?.[0]?.bankOfDeposit
          && this.bankInfo?.[0]?.bankAccount
        ) {
          this.purchContract.form.bankAccounts = `${this.bankInfo[0].bankOfDeposit}_${this.bankInfo[0].bankAccount}`;
        }

        this.purchContract.annexList = [...this.saleContract.annexList];
        this.purchContract.clause = this.saleContract.clause;

        this.purchContract.assetItemList = _.cloneDeep(this.saleContract.assetItemList);

        this.purchContract.form.totalAmount = 0;

        const isGeneralInvoice = this.purchContract.form.invoiceType.includes('GENERAL');

        this.purchContract.assetItemList.forEach(v => {
          v.originTaxRate = v.taxRate;
          v.originTaxAmount = v.taxAmount;
          v.originTaxPrice = v.taxPrice;

          const tariff = v.originTaxRate ? v.originTaxRate.replace('%', '') * 1 : 0; // 销售税率
          const taxRate = isGeneralInvoice ? 0 : v.taxRate ? v.taxRate.replace('%', '') * 1 : 0; // 采购税率
          const rate = (100 - tariff) / (100 - taxRate);

          v.taxPrice = Math.round(v.taxPrice * (1 - this.rateCache) * rate * 100) / 100;
          v.taxAmount = Math.round(v.taxPrice * v.serviceLimitYear * 100) / 100;
          v.planTaxAmount = v.taxAmount;

          this.purchContract.form.totalAmount += v.taxAmount * 100;

          const lastIndex = v.paymentPlanList.length - 1;
          let val = v.taxAmount;

          v.paymentPlanList.forEach((plan, index) => {
            plan.originPaymentAmount = plan.paymentAmount;
            plan.paymentDate = plan.paymentDate * 1 + THREE_DAYS_MILLSECONDS + '';

            if (index === lastIndex) {
              plan.paymentAmount = Math.round(val * 100) / 100;
            } else {
              plan.paymentAmount = Math.round(plan.originPaymentAmount * (1 - this.rateCache) * rate * 100) / 100;

              val -= plan.paymentAmount;
            }
          });
        });

        this.purchContract.form.totalAmount = this.purchContract.form.totalAmount
          ? this.purchContract.form.totalAmount / 100
          : undefined;

        this.providePurchPaymentPlan = this.purchContract.assetItemList.map(v => v.paymentPlanList).flat();
      }

      this.$emit('update:step', step);

      this.$nextTick(() => {
        document.querySelector('.step').scrollTop = 0;
      });
    },
    async getDetail () {
      const { body } = await saleContractDetail({
        id: this.$decode(this.id),
        findAnnexFlag: true,
        findAssetItemFlag: true,
        findClauseFlag: true,
        findPaymentPlanFlag: true,
      });

      this.customer = {
        customerCode: body.customerCode,
        customerContact: body.customerContact,
        customerContactId: body.customerContactId,
        customerHead: body.customerHead,
        id: body.customerId,
      };

      this.saleContract.form = {
        id: body.id,
        customerHead: body.customerHead,
        accountingUnit: body.accountingUnit,
        finalCustomerName: body.finalCustomerName,
        invoiceType: body.invoiceType,
        tariff: body.tariff || body.tariff === 0 ? parseInt(body.tariff * 100) + '%' : undefined,
        totalAmount: body.totalAmount || undefined,
        saleContractDate: [body.effectiveDate + '', body.contractDate + ''],
        salesmanName: body.salesmanName,
        signedDate: body.signedDate ? body.signedDate + '' : undefined,
        paymentMethod: body.paymentMethod,
        bankAccounts: `${body.bankOfDeposit}_${body.accounts}`,
        name: body.name,
        warrantyType: body.warrantyType || undefined,
      };

      this.provideSalePaymentPlan = [];

      this.saleContract.assetItemList = body.assetItemList.map(asset => {
        asset.taxRate = asset.taxRate || asset.taxRate === 0 ? parseInt(asset.taxRate * 1) + '%' : undefined;

        asset.planTaxAmount = asset.taxAmount;
        asset.uniqueValue = _.uniqueId();

        return asset;
      });

      this.provideSalePaymentPlan = this.saleContract.assetItemList.map(asset => asset.paymentPlanList || []).flat();

      this.saleContract.annexList = body.annexList || [];
      this.saleContract.clause = body.clause || [];
      this.saleContract.remark = body.contractRemarks;

      if (!this.isDirectlySalesCompany) {
        this.getPurchContractDetail(body.purchaseContractIds[0]);
      }
    },
    async getPurchContractDetail (id) {
      const { body } = await purchaseContractDetail({
        id,
      });

      this.purchContract.form = {
        id: body.id,
        customerHead: body.customerHead,
        accountingUnit: body.accountingUnit,
        finalCustomerName: body.finalCustomerName,
        invoiceType: body.invoiceType,
        taxRate: undefined,
        totalAmount: body.totalAmount,
        saleContractDate: [body.effectiveDate, body.contractDate],
        salesmanName: body.salesmanName,
        signedDate: body.signedDate,
        payWay: body.payWay,
        bankAccounts: `${body.bankName}_${body.account}`,
        name: body.name,
        warrantyType: body.warrantyType || undefined,
      };

      this.purchContract.remark = body.remark;
    },
    async onSubmit (draftFlag) {
      if (this.isDirectlySalesCompany) {
        await Promise.all([
          this.$refs.contractBaseInfo.validate(),
          this.$refs.relatedAssets.validate(),
          this.$refs.hospitalAssetManagementTerms.validate(),
          this.$refs.attachmentInfo.validate(),
          this.$refs.paymentPlanInfo.validate(),
        ]);
      }

      const saleContractParam = {
        id: this.saleContract.form.id,
        businessType: this.businessType,
        templateType: this.templateType,
        customerHead: this.saleContract.form.customerHead,
        accountingUnit: this.saleContract.form.accountingUnit,
        finalCustomerName: this.saleContract.form.finalCustomerName,
        invoiceType: this.saleContract.form.invoiceType,
        tariff: this.saleContract.form.tariff ? this.saleContract.form.tariff.replace('%', '') : undefined,
        totalAmount: this.saleContract.form.totalAmount,
        contractDate: this.saleContract.form.saleContractDate[1],
        effectiveDate: this.saleContract.form.saleContractDate[0],
        salesmanName: this.saleContract.form.salesmanName,
        signedDate: this.saleContract.form.signedDate,
        paymentMethod: this.saleContract.form.paymentMethod,
        accounts: this.saleContract.form.bankAccounts.split('_')[1],
        bankOfDeposit: this.saleContract.form.bankAccounts.split('_')[0],
        name: this.saleContract.form.name,
        assetItemList: this.saleContract.assetItemList.map(i => {
          i.paymentPlanList.forEach((p, index) => {
            p.periodsNumber = index + 1;
          });

          return {
            ...i,
            taxRate: i.taxRate.replace('%', ''),
          };
        }),
        annexList: this.saleContract.annexList,
        clause: this.saleContract.clause,
        remark: this.saleContract.remark,
        saleModel: this.isDirectlySalesCompany ? 'DIRECT_SALE' : 'XIAOZI_JOIN',
      };

      const purchContractParam = this.isDirectlySalesCompany ? undefined : {
        id: this.purchContract.form.id,
        businessType: this.businessType,
        templateType: this.templateType,
        taxRate: this.purchContract.form.taxRate ? this.purchContract.form.taxRate.replace('%', '') : undefined,
        totalAmount: this.purchContract.form.totalAmount,
        bankName: this.purchContract.form.bankAccounts.split('_')[0],
        account: this.purchContract.form.bankAccounts.split('_')[1],
        accountingUnit: this.purchContract.form.accountingUnit,
        supName: this.purchContract.form.supName,
        finalCustomerName: this.purchContract.form.finalCustomerName,
        invoiceType: this.purchContract.form.invoiceType,
        effectiveDate: this.purchContract.form.effectiveDate,
        contractDate: this.purchContract.form.contractDate,
        salesmanName: this.purchContract.form.salesmanName,
        signedDate: this.purchContract.form.signedDate,
        payWay: this.purchContract.form.payWay,
        name: this.purchContract.form.name,
        annexList: this.purchContract.annexList,
        assetItemList: this.purchContract.assetItemList.map(i => {
          i.paymentPlanList.forEach((p, index) => {
            p.periodsNumber = index + 1;
          });

          return {
            ...i,
            taxRate: i.taxRate.replace('%', ''),
          };
        }),
        clause: this.purchContract.clause,
        remark: this.purchContract.remark,
      };

      const params = {
        draftFlag,
        createPurchFlag: !this.isDirectlySalesCompany,
        purchContract: purchContractParam,
        saleContract: saleContractParam,
      };

      const api = this.id ? updateContract : newSaleContract;

      await api(params);

      this.back(true);
    },
  },
  created () {
    if (this.id) {
      this.getDetail();
    } else {
      if (this.isDirectlySalesCompany) {
        this.saleContract.form.paymentMethod = undefined;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.maintenance_template_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    overflow: auto;

    .step {
      height: 100%;
      overflow: auto;
    }

    .gap-12 {
      margin-bottom: 12px;
    }

    .common {
      background-color: #fff;
      padding: 12px;
      margin-bottom: 6px;

      .title {
        font-weight: bold;
      }

      /deep/ .ant-form-item {
        margin-bottom: 8px;

        .ant-form-item-label {
          float: left;
          width: 7em;
        }

        .ant-form-explain {
          font-size: 12px;
          height: auto;
        }
      }

      &.text {
        display: flex;
        align-items: center;

        span {
          width: 5em;
        }

        .ant-input {
          flex: 1;
        }
      }

      .extra-input {
        display: flex;
        align-items: center;
        padding: 24px 0 12px 0;

        span {
          width: 9em;
        }

        .ant-input {
          flex: 1;
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
    background-color: #fff;
    padding: 20px;

    .ant-btn {
      margin-left: 12px;
    }
  }
}
</style>

