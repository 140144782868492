<template>
  <div style="position: relative;display: flex; align-items: center; height: 37px;">
    <a-upload name="file" :file-list="files" :data="qiniuData" :list-type="listType" :accept="accept" :multiple="multiple"
      :action="uploadDomain" :showUploadList="showUploadList" :beforeUpload="handleBeforeUpload" @change="handleChange">
      <div style="position: relative;;">
        <a-button :disabled="fileList.length === uploadLimit || isShowLoading"> <a-icon type="upload" />{{ uploadText
        }}</a-button>
      </div>
    </a-upload>
    <a-spin v-if="isShowLoading" size="small"
      style="position: absolute; left: 50%; top: 50%; transform: translateY(-50%); cursor: not-allowed;" />
    <a-alert v-if="isError" type="error" message="上传失败请重新上传" banner closable style="margin-left: 0.5em;" />
  </div>
</template>
<script>
import Common from '@/api/common';
import _ from 'lodash';

const uploadDomain = 'https://upload.qiniup.com';

export default {
  name: 'XzUpload',
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
    listType: {
      type: String,
      default: 'text',
    },
    uploadText: {
      type: String,
      default: '上传附件',
    },
    uploadLimit: {
      type: Number,
      default: 99,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: () => {
        return 'image/png,image/jpeg,image/jpg,image/bmp';
      },
    },
    showUploadList: {
      type: Boolean,
      default: true,
    },
    max: {
      type: Number,
      default: 10,
    },
  },
  watch: {
    fileList: {
      deep: true,
      immediate: true,
      handler () {
        this.files = _.cloneDeep(this.fileList);

        console.log(this.files);
      },
    },
  },
  data () {
    return {
      uploadDomain,
      qiniuData: {
        key: '', // 图片名字处理
        token: '', // 七牛云token
        data: {},
      },
      downloadDomain: '',
      files: [],
      isShowLoading: false,
      isError: false,
    };
  },
  methods: {
    handleChange (info) {
      this.files = info.fileList;

      if (this.files[this.files.length - 1].status === 'done') {
        console.log(this.files);

        const fileData = this.files.map((file) => ({
          ...file,
          id: file.id || undefined,
          url: file.url || `${this.downloadDomain}/${file.response.key}`,
          name: file.name,
        }));
        this.$emit('update:fileList', fileData);

        this.isShowLoading = false;
        this.isError = false;
      } else if (this.files[this.files.length - 1].status === 'uploading') {
        this.isShowLoading = true;
      } else {
        this.isShowLoading = false;
        this.isError = true;
      }
    },
    handleBeforeUpload (file) {
      let isLt1M = file.size / 1024 / 10240 <= 1;
      if (!isLt1M) {
        this.$message.error(`上传文件大小超过${this.max}MB!`);
        return false;
      }

      this.qiniuData.data = file;
      this.qiniuData.key = `${new Date().getTime()}/${file.name}`;
    },
  },
  created () {
    Common.getQinNiuToken().then(res => {
      this.qiniuData.token = res.body.token;
      this.downloadDomain = res.body.domain;
    });
  },
};
</script>
<style scoped lang="scss">
.xz-upload-wrapper {}
</style>
