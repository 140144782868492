<template>
  <div class="collection">
    <div class="title gap-12">
      <span v-if="required" style="color: #f5222d; margin-right: 4px">*</span>
      收款计划
      <div class="alerts">
        <a-alert v-if="required && showWarning" message="收款计划不能为空" banner closable @close="showWarning = false" />
        <a-alert v-if="!isEqual" type="error" message="收款金额总和必须和合同金额相等" banner closable @close="isEqual = true" />
        <a-alert v-if="paymentDateMsg" type="error" :message="paymentDateMsg" banner closable @close="paymentDateMsg = ''" />
      </div>
    </div>
    <a-form-model :model="form" ref="collectionPlanInfo" :rules="rules">
      <xz-table :columns="collectionsColumns" :data="form.paymentPlanList" :scroll="{ x: noAction ? 0 : 1300, y: 600 }"
        :editable="true">
        <template #buttons>
          <a-button v-if="!noAction && templateType !== 'QUOTATION_LETTER'" type="primary" icon="plus"
            @click="newCollection">
            新增行
          </a-button>
        </template>
        <span slot="name" slot-scope="text">
          <a-form-model-item>
            {{ text }}
          </a-form-model-item>
        </span>
        <span slot="paymentDate" slot-scope="text, record, index">
          <a-form-model-item v-if="!noAction" :prop="'paymentPlanList.' + index + '.paymentDate'"
            :rules="rules.paymentDate">
            <a-date-picker v-model="record.paymentDate" valueFormat="x" :disabled-date="disabledStartDate" />
          </a-form-model-item>
          <span v-else>{{ formatDate(text) }}</span>
        </span>
        <span slot="paymentAmount" slot-scope="text, record, index">
          <a-form-model-item :prop="'paymentPlanList.' + index + '.paymentAmount'" :rules="rules.paymentAmount">
            <a-input-number v-if="!noAction" v-model="record.paymentAmount" :min="0" :step="0.01"
              :placeholder="templateType !== 'QUOTATION_LETTER' ? '请输入' : '根据履约标的自动计算'" style="width: 61.8%"
              :precision="2" :disabled="templateType === 'QUOTATION_LETTER'" />
            <span v-else>{{ text ? (text * 1).toLocaleString() : '' }}</span>
          </a-form-model-item>
        </span>
        <span slot="remark" slot-scope="text, record">
          <a-form-model-item>
            <a-input v-if="!noAction" v-model="record.remark" placeholder="请输入" />
            <span v-else>{{ text }}</span>
          </a-form-model-item>
        </span>
        <span slot="action" slot-scope="text, record, index">
          <a @click="deleteCollection(index)">删除</a>
        </span>
      </xz-table>
      <div v-if="templateType === 'RELOCATION'" style="display: flex; margin-top: 6px">
        <a-form-model-item prop="payDay">
          甲方在服务完成后
          <a-input-number v-if="!noAction" v-model="form.payDay" :min="0" :step="1" placeholder="请输入" style="width: 6em"
            :precision="0" :disabled="!form.paymentPlanList.length" />
          <span v-else>{{ form.payDay || '/' }}</span>
          日内向乙方付清人民币
        </a-form-model-item>
        <a-form-model-item prop="payMoney">
          <a-input-number v-if="!noAction" v-model="form.payMoney" :min="0" :step="0.01" placeholder="请输入"
            style="width: 10em" :precision="2" :disabled="!form.paymentPlanList.length" />
          <span v-else>{{ form.payMoney || '/' }}</span>
          元
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import * as Moment from 'dayjs';

const CollectionNames = {
  MAINTENANCE: '保养服务',
  SINGLE_REPAIR: '维修服务',
  WARRANTY: '保修服务',
  RELOCATION: '移机装机服务',
  TECHNOLOGY_SERVICE: '技术服务',
};

const collectionsColumns = [
  {
    title: '结算项类目',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '期数/次数',
    dataIndex: 'periodsNumber',
    key: 'periodsNumber',
  },
  {
    title: '收款日期',
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    scopedSlots: { customRender: 'paymentDate' },
  },
  {
    title: '收款金额',
    dataIndex: 'paymentAmount',
    key: 'paymentAmount',
    scopedSlots: { customRender: 'paymentAmount' },
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark',
    scopedSlots: { customRender: 'remark' },
  },
];

export default {
  name: 'CollectionPlanInfo',
  components: {
    xzTable,
  },
  props: {
    businessType: {
      type: String,
      default: '',
    },
    templateType: {
      type: String,
      required: true,
    },
    noAction: {
      type: Boolean,
      default: true,
    },
    providePaymentPlanList: {
      type: Array,
      default: () => [],
    },
    providePayDay: {
      type: [String, Number],
    },
    providePayMoney: {
      type: [String, Number],
    },
    required: {
      type: Boolean,
      default: true,
    },
    max: {
      type: Number,
      default: -1,
    },
    dateConfig: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    collectionsColumns () {
      return this.noAction || this.templateType === 'QUOTATION_LETTER'
        ? collectionsColumns
        : [
          ...collectionsColumns,
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            scopedSlots: { customRender: 'action' },
          },
        ];
    },
  },
  watch: {
    providePaymentPlanList: {
      handler (v) {
        this.form.paymentPlanList = [...v];
      },
      immediate: true,
    },
    providePayDay: {
      handler (v) {
        this.form.payDay = v;
      },
      immediate: true,
    },
    providePayMoney: {
      handler (v) {
        this.form.payMoney = v;
      },
      immediate: true,
    },
    max: {
      handler (v) {
        if (v !== -1) {
          let total = this.form.paymentPlanList.reduce((pre, cur) => {
            return pre + cur.paymentAmount * 100;
          }, 0);

          if (this.templateType === 'RELOCATION' && this.form.payMoney) {
            total += this.form.payMoney * 100;
          }

          total = Math.round(parseInt(total)) / 100;

          this.isEqual = !this.form.paymentPlanList.length || total === v;
        }
      },
    },
    dateConfig: {
      handler (v) {
        const value = v.value ? v.value : Moment(Moment().format('YYYY-MM-DD')).valueOf();

        let needAlert = this.form.paymentPlanList.some(plan => {
          return (
            plan.paymentDate
            && Moment(Moment(plan.paymentDate * 1).format('YYYY-MM-DD')).valueOf()
            < Moment(Moment(value * 1).format('YYYY-MM-DD')).valueOf()
          );
        });

        this.paymentDateMsg = '';

        if (needAlert) {
          this.paymentDateMsg = v.value ? '存在收款日期小于签订日期, 请重新选择' : `存在收款日期小于${Moment(value * 1).format('YYYY-MM-DD')}, 请重新选择`;

          this.$nextTick(() => {
            this.$refs.collectionPlanInfo.validate();
          },
          );
        }
      },
    },
  },
  data () {
    const validatePaymentAmount = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error('请输入'));
      } else if (value === 0) {
        callback(new Error('收款金额不能为0'));
      } else {
        if (this.max !== -1) {
          let total = this.form.paymentPlanList.reduce((prev, cur) => {
            return prev + cur.paymentAmount * 100;
          }, 0);

          if (this.templateType === 'RELOCATION' && this.form.payMoney) {
            total += this.form.payMoney * 100;
          }

          total = Math.round(parseInt(total)) / 100;

          this.isEqual = total === this.max;
        }

        callback();
      }
    };

    const validatePayDay = (rule, value, callback) => {
      const len = this.form.paymentPlanList.length;

      if (len === 1 && this.form.payMoney) {
        if (!value && value !== 0) {
          return callback(new Error('请输入'));
        } else if (value === 0) {
          return callback(new Error('输入不能为0'));
        }

        callback();
      } else if (len > 1) {
        if (!value && value !== 0) {
          return callback(new Error('请输入'));
        } else if (value === 0) {
          return callback(new Error('输入不能为0'));
        }

        return callback();
      }

      callback();
    };

    const validatePayMoney = (rule, value, callback) => {
      const len = this.form.paymentPlanList.length;

      if (len === 1 && this.form.payDay) {
        if (!value && value !== 0) {
          return callback(new Error('请输入'));
        } else if (value === 0) {
          return callback(new Error('输入不能为0'));
        } else {
          if (this.max !== -1) {
            let total = this.form.paymentPlanList.reduce((prev, cur) => {
              return prev + cur.paymentAmount * 100;
            }, 0);

            if (this.templateType === 'RELOCATION') {
              total += this.form.payMoney * 100;
            }

            total = Math.round(parseInt(total)) / 100;

            this.isEqual = total === this.max;
          }

          return callback();
        }
      } else if (len > 1) {
        if (!value && value !== 0) {
          return callback(new Error('请输入'));
        } else if (value === 0) {
          return callback(new Error('输入不能为0'));
        } else {
          if (this.max !== -1) {
            let total = this.form.paymentPlanList.reduce((prev, cur) => {
              return prev + cur.paymentAmount * 100;
            }, 0);

            if (this.templateType === 'RELOCATION') {
              total += this.form.payMoney * 100;
            }

            total = Math.round(parseInt(total)) / 100;

            this.isEqual = total === this.max;
          }

          return callback();
        }
      }

      callback();
    };

    const validatePaymentDate = (rule, value, callback) => {
      this.paymentDateMsg = '';

      if (!value) {
        return callback(new Error('请选择'));
      } else if (this.dateConfig?.value) {
        const ret = Moment(Moment(value * 1).format('YYYY-MM-DD')).valueOf() < Moment(Moment(this.dateConfig.value * 1).format('YYYY-MM-DD')).valueOf();

        if (ret) {
          this.paymentDateMsg = '存在收款日期小于签订日期, 请重新选择';
          return callback(new Error('收款日期不能小于签订日期'));
        }
      } else if (!this.dateConfig?.value) {
        const ret = Moment(Moment(value * 1).format('YYYY-MM-DD')).valueOf() < Moment(Moment().format('YYYY-MM-DD')).valueOf();

        if (ret) {
          this.paymentDateMsg = `存在收款日期小于${Moment(value * 1).format('YYYY-MM-DD')}, 请重新选择`;
          return callback(new Error('收款日期不能小于当日日期'));
        }
      }

      callback();
    };

    return {
      form: {
        paymentPlanList: [],
        payDay: undefined,
        payMoney: undefined,
      },
      rules: {
        paymentDate: [{ required: true, validator: validatePaymentDate, trigger: ['change', 'blur'] }],
        paymentAmount: [{ required: true, validator: validatePaymentAmount, trigger: 'blur' }],
        payDay: [{ validator: validatePayDay, trigger: 'blur' }],
        payMoney: [{ validator: validatePayMoney, trigger: 'blur' }],
      },
      showWarning: false,
      isEqual: true,
      paymentDateMsg: '',
    };
  },
  methods: {
    disabledStartDate (startValue) {
      let ret = false;

      if (this.dateConfig?.value) {
        ret
          = Moment(Moment(startValue.valueOf()).format('YYYY-MM-DD')).valueOf()
          < Moment(Moment(this.dateConfig.value * 1).format('YYYY-MM-DD')).valueOf();
      } else {
        ret
          = Moment(Moment(startValue.valueOf()).format('YYYY-MM-DD')).valueOf()
          < Moment(Moment().format('YYYY-MM-DD')).valueOf();
      }

      return ret;
    },
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    makeOrder () {
      this.form.paymentPlanList.forEach((v, index) => {
        v.periodsNumber = index + 1;
      });
    },
    newCollection () {
      // 收款计划
      this.form.paymentPlanList.push({
        name: CollectionNames[this.businessType],
        periodsNumber: undefined,
        paymentDate: undefined,
        paymentAmount: undefined,
        remark: undefined,
      });

      this.makeOrder();
    },
    deleteCollection (index) {
      this.form.paymentPlanList.splice(index, 1);

      if (this.templateType === 'RELOCATION' && !this.form.paymentPlanList.length) {
        this.form.payDay = '';
        this.form.payMoney = '';
      }

      this.makeOrder();
    },
    validate () {
      if (this.max !== -1) {
        let total = this.form.paymentPlanList.reduce((pre, cur) => {
          return pre + cur.paymentAmount * 100;
        }, 0);

        if (this.templateType === 'RELOCATION' && this.form.payMoney) {
          total += this.form.payMoney * 100;
        }

        total = Math.round(parseInt(total)) / 100;

        this.isEqual = total === this.max;
      }

      return new Promise((resolve, reject) => {
        if (!this.form.paymentPlanList.length) {
          this.showWarning = true;
          reject(new Error('fail'));
        } else if (!this.isEqual) {
          reject(new Error('fail'));
        } else {
          this.$refs.collectionPlanInfo.validate(valid => {
            if (valid) {
              this.$emit('update:providePaymentPlanList', this.form.paymentPlanList);

              if (this.templateType === 'RELOCATION') {
                this.$emit('update:providePayDay', this.form.payDay);
                this.$emit('update:providePayMoney', this.form.payMoney);
              }

              resolve(valid);
            } else {
              reject(new Error('fail'));
            }
          });
        }
      });
    },
  },
  created () { },
};
</script>

<style scoped lang="scss">
.gap-12 {
  margin-bottom: 12px;
}

.collection {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  .title {
    font-weight: bold;
    display: flex;
    align-items: center;

    .alerts {
      flex: 1;
      margin-left: 12px;

      .ant-alert {
        display: block;
      }
    }
  }

  /deep/ .ant-form-item {
    margin-bottom: 12px;

    .ant-form-explain {
      display: none;
    }
  }

  /deep/ .ant-table-tbody>tr>td {
    padding: 8px 16px 0;
    overflow-wrap: break-word;
  }

  &.text {
    display: flex;
    align-items: center;

    span {
      width: 5em;
    }

    .ant-input {
      flex: 1;
    }
  }
}
</style>
