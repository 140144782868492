<template>
  <div :class="['common_form_edit', showType === 'dialog' ? 'common_form_edit_dialog' : '']">
    <div class="edit_log" v-if="id" @click="logDrawerVisible=true">
      <i class="el-icon-warning-outline"></i>
      <span class="mt-1">动态信息</span>
    </div>
    <parser ref="newOrderModelRef" v-if="isLoadedTemplate" :form-conf="schemaDesc" :formModel="orderModel"></parser>
    <bottom-panel :showType="showType">
      <el-popover
        v-if="validateMsgs && validateMsgs[0]"
        placement="top"
        width="160"
        popper-class="valid_msg_list"
        trigger="hover">
        <ul>
          <li v-for="(m,index) in validateMsgs" :key="index" :title="m">{{m}}</li>
        </ul>
        <el-link slot="reference" type="danger" class="mr-2"><i class="el-icon-warning mr-1" ></i>校验不通过</el-link>
      </el-popover>
      <el-button type="primary" plain @click="handleClose">{{ id ? '取消' : '返回' }}</el-button>
      <template v-if="customizeButton.length">
        <template v-if="isNewProcess">
          <el-button  v-for="btns in customizeButton" :key="btns.id" :style="btns.buttonStyleMsg"  @click="handleBtnClickWithNewProcess(btns)">{{btns.buttonStyleMsg?btns.buttonStyleMsg.text:btns.name}}</el-button>
        </template>
        <template v-else>
          <el-button type="primary" v-for="btns in customizeButton" :key="btns.id" :icon="btns.icon"  v-auth="btns.pcAuthCode" @click="customBtsHandle(btns)">{{btns.name}}</el-button>
        </template>
      </template>
    </bottom-panel>
    <el-drawer
      custom-class="operation_drawer"
      title="动态信息"
      size="24%"
      :visible.sync="logDrawerVisible"
      direction="rtl">
      <div class="pl-2 pt-2 pr-2 pb-2">
        <el-timeline v-if="orderModel.dynamicInformList && orderModel.dynamicInformList[0]">
          <el-timeline-item
            v-for="(activity, index) in orderModel.dynamicInformList"
            :key="index"
            placement="top"
            type="primary"
            :timestamp="activity.operateTime | date-format">
              <div>
                <div>{{activity.actionDesc}}</div>
                <div>{{activity.content}}</div>
              </div>
          </el-timeline-item>
        </el-timeline>
        <el-empty v-else></el-empty>
      </div>
    </el-drawer>
    <el-dialog :title="processTitle"
      width="600px"
      :visible="true"
      @close="processDialogVisible=false"
      v-if="processDialogVisible">
      <el-form ref="approveMsgForm" label-width="120px" :model="approveMsg" :rules="approveMsgRules">
        <el-form-item label="处理意见"
          prop="approveComments"
          v-if="approveCommentVisible">
          <el-input type="textarea" v-model="approveMsg.approveComments"></el-input>
        </el-form-item>
        <el-form-item label="后续处理人"
          prop="approveUserMsg"
          v-if="approveUserVisible">
          <span slot="label">后续处理人
            <el-tooltip class="item" effect="dark" content="若找不到处理人，请联系管理员" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </span>
          <VirtualUserSelector  v-if="approveTypeMsg && approveTypeMsg.assignedType==='SELF_SELECT' && approveTypeMsg.selfSelect.scope !== 'DESIGNATED'"
            :scope="approveTypeMsg.selfSelect.scope"
            v-model="approveMsg.approveUserMsg"/>
          <virtual-selector v-else
            placeholder="请选择处理人"
            :value="approveMsg.approveUserMsg.id"
            :name="approveMsg.approveUserMsg.name"
            :optionList="approveUserList"
            filterable
            :option="{
              label: 'name',
              key: 'id',
            }"
            @change="val=>approveMsg.approveUserMsg=val">
          </virtual-selector>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="processDialogVisible=false">取消</el-button>
        <el-button type="primary" @click="hanldleProcessAction">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { commonFormConfigJson } from './urlConfig.js';
import { queryLayoutByNodeApi, queryFormProcessInfo } from '@/api/formSetting';
import { getDefaultValueByTag, flatternFields } from '@/components/form-generator/utils/component.js';
import Parser from '@/components/form-generator/parser/index.js';
import { customSettingFormBtnHandle, getCommonQueryPageDetail } from '@/api/commonForm';
import VirtualSelector from '@/components/common/virtualSelector';
import VirtualUserSelector from '@/components/virtualUserSelector';
import { mapGetters } from 'vuex';

export default {
  components: {
    Parser,
    VirtualSelector,
    VirtualUserSelector,
  },
  props: {
    id: String,
    templateCode: {
      type: String,
    },
    configCode: {
      required: true,
    },
    customerId: {
      type: String,
    },
    renderKey: {
      type: String,
    },
    showType: {
      type: String,
    },
  },
  provide () {
    return {
      customerVal: {
        customerId: this.customerId,
        renderKey: this.renderKey,
      },
    };
  },
  computed: {
    ...mapGetters(['currentUserInfo']),
    curPageConfig () {
      return commonFormConfigJson[this.configCode];
    },
    routeTab () {
      const text = this.id ? '编辑' : '新增';
      return `${this.curPageConfig.abbreviation}${text}`;
    },
    approveMsgRules () {
      return {
        approveUserMsg: [
          {
            validator: (rule, value, callback) => {
              if (this.approveUserVisible && (value === null || value === undefined || Object.keys(value).length === 0)) {
                callback(new Error('请选择处理人'));
              }
              callback();
            },
            trigger: 'blur',
          },
          {
            required: this.approveUserVisible,
            message: '请选择处理人',
            trigger: ['blur', 'change'],
          },
        ],
        approveComments: [
          {
            required: this.approveCommentVisible,
            message: '请填写处理意见',
            trigger: ['blur', 'change'],
          },
          {
            max: 200,
            message: '处理意见最长不可超过200字符',
            trigger: ['blur', 'change'],
          },
        ],
      };
    },
  },
  mounted () {
    document.title = this.routeTab;
    if (this.id) {
      this.getOrderDetail();
    } else if (this.templateCode) {
      this.getTemplateInfo({
        code: this.templateCode,
        status: 'NEW',
      }).then(() => {
        this.getViewModel(this.schemaDesc?.fields);
        this.isLoadedTemplate = true;
      });
    }
  },
  data () {
    return {
      validateMsgs: [],
      customizeButton: [],
      schemaDesc: {},
      isLoadedTemplate: false,
      orderModel: {},
      currentInfo: {},
      logDrawerVisible: false,
      isNewProcess: false,
      currentBtnAction: null,
      processTitle: '',
      processDialogVisible: false,
      approveTypeMsg: {},
      approveMsg: {
        approveUserMsg: null,
        approveComments: null,
      },

      approveUserVisible: false,
      approveCommentVisible: false,
      approveUserList: [],
    };
  },
  methods: {
    handleFormSave (otherData) {
      const {pcUrl, httpMethod, name, buttonStyleMsg, validateRequiredFlag} = this.currentBtnAction;
      const param = {
        ...this.orderModel,
        typeMsg: this.templateType,
        customizeSystemConfigCode: this.configCode,
        validateRequiredFlag,
        ...otherData,
      };
      return customSettingFormBtnHandle({
        url: pcUrl,
        method: httpMethod,
        param,
      }).then(()=>{
        this.$message.success(`${buttonStyleMsg?.text || name || '操作'}成功`);
        this.handleClose();
      });
    },
    hanldleProcessAction () {
      this.$refs.approveMsgForm.validate(valid=>{
        if (valid) {
          this.processDialogVisible = false;
          this.handleFormSave(this.approveMsg);
        }
      });
    },
    handleBtnClickWithNewProcess (action) {
      const {buttonFlag, buttonStyleMsg, validateRequiredFlag } = action;
      this.currentBtnAction = action;
      let validateAction = Promise.resolve();
      if (validateRequiredFlag) {
        validateAction = this.$refs.newOrderModelRef.validForm();
      }
      this.validateMsgs.splice(0, this.validateMsgs.length);
      validateAction.then(()=>{
        if (buttonFlag === 'SAVE_SUBMIT' && this.approveTypeMsg?.assignedType && this.approveTypeMsg?.assignedType !== 'ASSIGN_USER' && this.approveTypeMsg?.assignedType !== 'LEADER') {
          // 提交
          this.approveCommentVisible = false;
          this.approveUserVisible = true;
          this.approveUserList = [];
          this.approveMsg.approveUserMsg = {};
          const { assignedType, formUser, selfSelect } = this.approveTypeMsg;
          if (assignedType === 'FORM_USER') {
            this.approveMsg.approveUserMsg = this.orderModel[formUser] || {};
            this.approveUserList = [this.orderModel[formUser]];
          } else if (assignedType === 'SELF_SELECT' && selfSelect.scope === 'DESIGNATED') {
            this.approveUserList = selfSelect.scopeList;
          }
          this.processTitle = buttonStyleMsg?.text;
          this.processDialogVisible = true;
        } else if (buttonFlag === 'AGREE') {
          // 表单内人员、提交时自选需要弹窗
          this.approveMsg = {
            approveUserMsg: {},
            approveComments: buttonStyleMsg?.text || '同意',
          };
          this.approveUserVisible = false;
          this.approveCommentVisible = true;
          this.approveUserList = [];
          if ((this.approveTypeMsg?.assignedType === 'FORM_USER' || this.approveTypeMsg?.assignedType === 'SELF_SELECT')) {
            this.approveUserVisible = true;
            const { assignedType, formUser, selfSelect } = this.approveTypeMsg;
            if (assignedType === 'FORM_USER') {
              this.approveMsg.approveUserMsg = this.orderModel[formUser] || {};
              this.approveUserList = [this.orderModel[formUser]];
            } else if (assignedType === 'SELF_SELECT' && selfSelect.scope === 'DESIGNATED') {
              this.approveUserList = selfSelect.scopeList;
            }
          }
          this.processTitle = buttonStyleMsg?.text;
          this.processDialogVisible = true;
        } else if (buttonFlag === 'REJECT') {
          // 驳回
          this.approveUserVisible = false;
          this.approveCommentVisible = true;
          this.approveMsg = {
            approveUserMsg: null,
            approveComments: buttonStyleMsg?.text || '驳回',
          };
          this.processTitle = buttonStyleMsg?.text;
          this.processDialogVisible = true;
        } else if (buttonFlag === 'REFUSE') {
          // 拒绝
          this.approveUserVisible = false;
          this.approveCommentVisible = true;
          this.approveMsg = {
            approveUserMsg: null,
            approveComments: buttonStyleMsg?.text || '拒绝',
          };
          this.processTitle = buttonStyleMsg?.text;
          this.processDialogVisible = true;
        } else {
          // 不需要弹窗
          this.handleFormSave();
        }
      }).catch(error=>{
        Object.keys(error).forEach(k=>{
          this.validateMsgs = this.validateMsgs.concat(error[k].map(t=>t.message));
        });
      });
    },
    customBtsHandle (action) {
      let validateAction = Promise.resolve();
      if (action.validateRequiredFlag) {
        validateAction = this.$refs.newOrderModelRef.validForm();
      }
      this.validateMsgs.splice(0, this.validateMsgs.length);
      validateAction.then(()=>{
        this.currentBtnAction = action;
        this.handleFormSave();
      }).catch(error=>{
        Object.keys(error).forEach(k=>{
          this.validateMsgs = this.validateMsgs.concat(error[k].map(t=>t.message));
        });
      });
    },
    getTemplateInfo (param) {
      return queryLayoutByNodeApi({
        ...param,
      }).then(rs => {
        this.templateType = rs.body?.customizeBusinessTypeResDTO;
        this.isNewProcess = !!rs.body.customizeBusinessTypeResDTO.customizeProcessFlag;
        let formConf = rs.body?.customizeBusinessProcessResDTO?.customizeLayoutResDTO?.schemaDesc || {};
        if (this.isNewProcess) {
          // 设置系统字段隐藏
          const fieldMap = flatternFields(formConf?.fields);
          Object.values(fieldMap).forEach(t=>{
            if (t?.__config__?.isReservedField) {
              t.__config__.visible = false;
            }
          });
          this.getApproveMsg(rs.body?.customizeBusinessTypeResDTO?.code);
        }

        this.schemaDesc = formConf;
        if (this.isNewProcess) {
          this.customizeButton = rs.body.customizeBusinessProcessResDTO?.customizeButtonResDTOList?.filter(t=>!!t.checkFlag) || [];
        } else {
          this.customizeButton = rs.body.customizeBusinessProcessResDTO?.customizeButtonResDTOList || [];
        }
      });
    },
    getApproveMsg (customizeBusinessTypeCode) {
      queryFormProcessInfo({
        id: this.id,
        customizeBusinessTypeCode,
      }).then(ret=>{
        this.approveTypeMsg = ret.body || {};
      });
    },
    getOrderDetail () {
      getCommonQueryPageDetail({
        id: this.id,
        systemConfigCodeFlag: this.configCode,
      }).then(res => {
        this.currentInfo.deviceId = res.body?.deviceMsg?.id || '';
        this.currentInfo.customizeBusinessScenarioCode = res.body?.typeMsg?.customizeBusinessScenarioCode || '';
        this.getTemplateInfo({
          customizeBusinessTypeId: res.body?.typeMsg?.id,
          status: res.body?.status,
        }).then(() => {
          if (this.isNewProcess && res.body?.status === 'FINISHED') {
            this.schemaDesc.disabled = true;
          }
          // 编辑单据按钮从单据详情中取值
          if (this.isNewProcess) {
            this.customizeButton = res.body?.buttonMsg?.filter(t=>!!t.checkFlag) || [];
          }
          this.getViewModel(this.schemaDesc?.fields, res.body);
          Object.keys(res.body).forEach(key => {
            if (res.body[key] !== null && res.body[key] !== undefined) {
              this.$set(this.orderModel, key, res.body[key]);
            }
          });
          this.isLoadedTemplate = true;
        });
      });
    },
    getViewModel (nodeList) {
      nodeList
        && nodeList.forEach(node => {
          if (node?.__config__?.layout === 'groupRowFormItem') {
            this.getViewModel(node?.__config__?.children);
          } else {
            this.$set(this.orderModel, node.__config__.renderKey, getDefaultValueByTag(node));
          }
        });
    },
    handleClose () {
      if (this.showType === 'dialog') {
        this.$emit('closeAddDeviceDialog');
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.common_form_edit {
  position: relative;
  height: 100%;
  .edit_log {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 26px;
    height: 108px;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-lr;
    z-index: 1000;
    cursor: pointer;
    background: #6FB8FF;
    border-radius: 8px 0px 0px 8px;
    &:hover {
      background: #0F89FF;;
    }

    &>span {
      letter-spacing: 4px;
    }
  }

  /deep/ .operation_drawer {
    min-width: 336px;
  }
}

.common_form_edit_dialog {
  > .el-row {
    max-height: 70vh !important;
    overflow-y: auto !important;
  }
}

  .el-popover.valid_msg_list>ul {
    list-style: none;
    color: red;
    max-height: 240px;
    overflow-y:auto;
    &>li {
      line-height: 24px;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
