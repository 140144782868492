<template>
  <div class="batch-import-dialog-wrapper">
    <a-form-model ref="form" :model="form" :wrapper-col="wrapperCol">
      <a-form-model-item v-for="e in config" :key="e.key" :prop="e.key" style="margin-bottom: 0">
        <div class="assemble-item-wrapper">
          <div>
            <a-checkbox v-model="checkboxForm[e.key]"></a-checkbox>
            {{ e.title }}
          </div>
          <a-input v-if="e._type === 'input'" v-model="form[e.key]" :placeholder="`请输入${e.title}`" />
          <a-date-picker
            v-if="e._type === 'date'"
            v-model="form[e.key]"
            valueFormat="x"
            style="width: 100%"
            allow-clear
          ></a-date-picker>
          <a-input-number
            v-if="e._type === 'number'"
            v-model="form[e.key]"
            :min="e.min"
            :placeholder="`请输入${e.title}`"
          />
          <a-select v-if="e._type === 'select'" v-model="form[e.key]" :placeholder="`请选择${e.title}`">
            <a-select-option v-for="opt in e._options" :key="opt.value" :value="opt.value">
              {{ opt.label }}
            </a-select-option>
          </a-select>
          <a-range-picker
            v-if="e._type === 'dateRange'"
            v-model="form[e.key]"
            valueFormat="x"
            style="width: 100%"
            allow-clear
          ></a-range-picker>
          <template v-if="e.subs">
            <div v-for="child in e.subs" :key="child.key" style="width: 110px; margin-left: 8px;">
              <a-select v-if="child._type === 'select'" v-model="form[child.key]" :placeholder="`请选择${child.title}`">
                <a-select-option v-for="opt in child._options" :key="opt.value" :value="opt.value">
                  {{ opt.label }}
                </a-select-option>
              </a-select>
            </div>
          </template>
        </div>
      </a-form-model-item>
    </a-form-model>
    <div class="button-group">
      <a-button @click="handleCancel">取消</a-button>
      <a-button type="primary" @click="handleConfirm">确定</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BatchImport',
  props: {
    config: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    config: {
      handler (newV) {
        const form = {};
        const checkboxForm = {};

        if (Array.isArray(newV)) {
          newV.forEach(v => {
            form[v.key] = v.default;
            checkboxForm[v.key] = false;

            if (v.subs) {
              v.subs.forEach(child => {
                form[child.key] = child.default;
              });
            }
          });
        }

        this.form = form;
        this.checkboxForm = checkboxForm;
      },
      immediate: true,
    },
  },
  data () {
    return {
      form: {},
      checkboxForm: {},
      wrapperCol: { span: 24 },
    };
  },
  methods: {
    handleCancel () {
      this.$emit('cancel');
    },
    handleConfirm () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {};

          for (const k in this.checkboxForm) {
            if (this.checkboxForm[k]) {
              data[k] = this.form[k];
            }

            const ret = this.config.find(conf => conf.key === k);

            if (ret?.subs) {
              ret.subs.forEach(child => {
                data[child.key] = this.form[child.key];
              });
            }
          }

          this.$emit('confirm', data);
        }
      });
    },
  },
};
</script>

  <style lang="scss" scoped>
.batch-import-dialog-wrapper {
  .assemble-item-wrapper {
    display: flex;
    align-items: center;

    & > *:first-child {
      width: 120px;
    }

    & > *:nth-child(2) {
      flex: 1;
    }
  }

  .button-group {
    width: 100%;
    padding: 16px;
    text-align: right;
    .ant-btn:first-of-type {
      margin-right: 12px;
    }
  }
}
</style>
