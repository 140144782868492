<template>
  <div>
    <a-tabs :activeKey="activeKey" @change="callback">
      <a-tab-pane key="assets" tab="资产数据">
      </a-tab-pane>
      <a-tab-pane key="deviceLib" tab="设备库">
      </a-tab-pane>
    </a-tabs>
    <a-form-model :model="form" :wrapper-col="{ offset: 4 }">
      <a-row>
        <a-col :span="8">
          <a-form-model-item>
            <a-input v-model="form.deviceName" placeholder="请输入设备名称" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item>
            <a-input v-model="form.deviceModel" placeholder="请输入设备型号" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item>
            <a-input v-model="form.deviceEnterpriseName" placeholder="请输入生产厂家" allowClear />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-model-item>
            <a-input v-model="form.deviceBrandName" placeholder="请输入品牌" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-if="activeKey === 'assets'">
          <a-form-model-item>
            <a-input v-model="form.deviceSerialNumber" placeholder="请输入序列号" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="8" v-else>
        </a-col>
        <a-col :span="8">
          <a-form-model-item>
            <a-button @click="handleReset">
              重置
            </a-button>
            <a-button type="primary" style="margin-left: 10px;" @click="handleSearch">
              查询
            </a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <ConfigProvider>
      <template #renderEmpty>
        <a-empty :image="require('@/assets/empty.svg')" description=''>
          <div style="padding-bottom: 40px;">
            暂无数据，可点击<span @click="toAddDevices" style="color: #237FFA;cursor: pointer;"> 新增 </span>按钮新增设备，或者在小紫设备库进行选择
          </div>
        </a-empty>
      </template>
      <a-table ref="tableRef" :scroll="{ y: 260 }" :data-source="totalData[activeKey].tableData" :columns="columns"
        :rowKey="(row) => row.deviceId" :row-selection="rowSelection" :pagination="false">
      </a-table>
    </ConfigProvider>
    <h4 :style="{ padding: '12px 0 0 0' }">已选择设备</h4>
    <a-table :scroll="{ y: 260 }" :data-source="selectedXnTable" :columns="columns" :rowKey="(row) => row.deviceId"
      :pagination="false">
    </a-table>

    <!-- 新增设备 -->
    <el-dialog title="新增设备" :visible.sync="addDeviceDialog" :append-to-body="true" :close-on-click-modal="false"
      :close-on-press-escape="false" @close="hideAddDeviceDialog" width="90vw" top="5vh">
      <div v-if="addDeviceDialog && templateCode" style="height: 80vh;">
        <CustomForm configCode="DEVICE_FILE" :templateCode="templateCode" :customerId="customerId" showType="dialog"
          renderKey="customerMsg" @closeAddDeviceDialog="closeAddDeviceDialog" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ConfigProvider } from 'ant-design-vue';
import { getDevices } from '@/api/contract';
import { getFormTypeListByConfigId } from '@/api/commonForm';
import * as Moment from 'dayjs';
import CustomForm from '@/components/commonForm/edit.vue';

const YI_XIU_BAO = '1';
const DEVICE_DATA_LIBRARY = '2';

const formatDate = (text, format = 'YYYY-MM-DD') => {
  return text ? Moment(text * 1).format(format) : '/';
};

const COMMON_COLUMNS = [
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    key: 'deviceName',
    width: 100,
    ellipsis: true,
  },
  {
    title: '设备型号',
    dataIndex: 'deviceModel',
    key: 'deviceModel',
    width: 100,
    ellipsis: true,
  },
  {
    title: '生产厂家',
    dataIndex: 'deviceEnterpriseName',
    key: 'deviceEnterpriseName',
    width: 100,
    ellipsis: true,
  },
  {
    title: '设备品牌',
    dataIndex: 'deviceBrandName',
    key: 'deviceBrandName',
    width: 100,
    ellipsis: true,
  },
  {
    title: '设备类目',
    dataIndex: 'deviceCategoryName',
    key: 'deviceCategoryName',
    width: 100,
    ellipsis: true,
  },
  {
    title: '注册证号',
    dataIndex: 'deviceRegisterCode',
    key: 'deviceRegisterCode',
    width: 100,
    ellipsis: true,
  },
  {
    title: '厂家编号',
    dataIndex: 'manufacturerCode',
    key: 'manufacturerCode',
    width: 100,
    ellipsis: true,
  },
];

export default {
  components: {
    ConfigProvider,
    CustomForm,
  },
  props: {
    customerId: {
      type: String,
      default: '',
    },
    selectType: {
      type: String,
      default: 'checkbox',
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    assetSelectedRows: { // 资产数据已选数据
      type: Array,
      default: () => [],
    },
    libSelectedRows: { // 设备库已选数据
      type: Array,
      default: () => [],
    },
  },
  computed: {
    rowSelection () {
      return {
        type: this.selectType,
        columnTitle: this.hideSelectAll ? ' ' : '',
        onChange: (rowkeys, rows) => {
          if (rows.length === 1) {
            this.checkedCustomerId = rows[0].customerId;
            this.totalData[this.activeKey].tableData.map(item => {
              if (item.customerId !== this.checkedCustomerId) {
                item.disabled = true;
              } else {
                item.disabled = false;
              }
            });
            this.totalData[this.activeKey].tableData = [...this.totalData[this.activeKey].tableData];
          }
          if (rows.length === 0) {
            this.totalData[this.activeKey].tableData.map(item => {
              item.disabled = false;
            });
            this.totalData[this.activeKey].tableData = [...this.totalData[this.activeKey].tableData];
          }
          if (this.selectType === 'radio') {
            this.totalData[this.activeKey].selectedData = rows;

            if (this.activeKey === 'assets') {
              this.totalData.deviceLib.selectedData = [];
            } else if (this.activeKey === 'deviceLib') {
              this.totalData.assets.selectedData = [];
            }
          } else {
            this.totalData[this.activeKey].selectedData = rows;

            this.actualSelectedIds = rows.map(r => r.deviceId);
          }
        },
        getCheckboxProps: (record) => {
          let ret = {};

          if (this.selectType === 'radio') {
            ret = {
              props: {
                disabled: this.selectedIds.includes(record.deviceId),
              },
            };
          } else {
            ret = {
              props: {
                disabled: record.disabled,
                defaultChecked: this.actualSelectedIds.includes(record.deviceId),
              },
            };
          }

          return ret;
        },
      };
    },
    columns () {
      return this.activeKey === 'assets' ? [
        {
          title: '资产编号',
          dataIndex: 'deviceCode',
          key: 'deviceCode',
          width: 100,
          ellipsis: true,
        },
        ...COMMON_COLUMNS,
        {
          title: '设备序列号',
          dataIndex: 'deviceSerialNumber',
          key: 'deviceSerialNumber',
          width: 100,
          ellipsis: true,
        },
        {
          title: '位置',
          dataIndex: 'placeDepartment',
          key: 'placeDepartment',
          width: 100,
          ellipsis: true,
        },
        {
          title: '装机日期',
          dataIndex: 'installationDate',
          key: 'installationDate',
          width: 100,
          customRender: (text) => {
            return formatDate(text);
          },
        },
      ] : COMMON_COLUMNS;
    },
    selectedXnTable () {
      return [...this.totalData.assets.selectedData, ...this.totalData.deviceLib.selectedData];
    },
  },
  watch: {
    assetSelectedRows: {
      handler (rows) {
        this.totalData.assets.selectedData = [...rows];
      },
      immediate: true,
    },
    libSelectedRows: {
      handler (rows) {
        this.totalData.deviceLib.selectedData = [...rows];
      },
      immediate: true,
    },
    selectedIds: {
      handler (v) {
        this.actualSelectedIds = [...v];
      },
      immediate: true,
    },

  },
  data () {
    return {
      templateCode: '',
      checkedCustomerId: '',
      hideSelectAll: false,
      actualSelectedIds: [],
      form: {
        customerId: this.customerId,
        deviceName: undefined,
        deviceModel: undefined,
        deviceEnterpriseName: undefined,
        deviceBrandName: undefined,
        deviceSerialNumber: undefined,
      },
      activeKey: 'assets',
      totalData: {
        assets: {
          form: {
            deviceName: undefined,
            deviceModel: undefined,
            deviceEnterpriseName: undefined,
            deviceBrandName: undefined,
            deviceSerialNumber: undefined,
          },
          page: {
            pageNum: 1,
            pageSize: 10,
          },
          tableData: [],
          selectedData: [],
          total: Infinity,
        },
        deviceLib: {
          form: {
            deviceName: undefined,
            deviceModel: undefined,
            deviceEnterpriseName: undefined,
            deviceBrandName: undefined,
            deviceSerialNumber: undefined,
          },
          page: {
            pageNum: 1,
            pageSize: 10,
          },
          tableData: [],
          selectedData: [],
          total: Infinity,
        },
      },
      addDeviceDialog: false,
    };
  },
  methods: {
    async toAddDevices () {
      const data = await getFormTypeListByConfigId({ customizeSystemConfigCode: 'DEVICE_FILE' });
      if (data.body && data.body.length) {
        const openItem = data.body.find(item => item.status === true);
        this.templateCode = openItem.code;
      }
      if (this.templateCode) {
        this.addDeviceDialog = true;
      } else {
        this.$message.warning('无法新增设备');
      }
    },
    closeAddDeviceDialog () {
      this.hideAddDeviceDialog();
      // 更新设备列表
      this.callback(this.activeKey);
    },
    hideAddDeviceDialog () {
      this.addDeviceDialog = false;
    },
    callback (key) {
      this.totalData[this.activeKey].form = { ...this.form };

      this.activeKey = key;

      this.form = { ...this.totalData[this.activeKey].form };

      if (!this.totalData[this.activeKey].tableData.length) {
        this.handleSearch();
      }
    },
    handleReset () {
      for (const k in this.totalData[this.activeKey].form) {
        this.totalData[this.activeKey].form[k] = undefined;

        this.form[k] = undefined;
      }

      this.handleSearch();
    },
    handleSearch () {
      this.totalData[this.activeKey].page.pageNum = 1;
      this.totalData[this.activeKey].tableData = [];

      this.loadData();
    },
    async loadData () {
      const source = this.activeKey === 'assets' ? YI_XIU_BAO : DEVICE_DATA_LIBRARY;

      const { body } = await getDevices({
        ...this.form,
        source,
        ...this.totalData[this.activeKey].page,
      });

      this.totalData[this.activeKey].tableData = [...this.totalData[this.activeKey].tableData, ...body.list];
      this.totalData[this.activeKey].total = body.total;

      // 判断是否支持全选：如存在不同医院设备，则不支持全选
      this.hideSelectAll = false;
      if (this.totalData[this.activeKey].tableData.length > 1) {
        let firstCustomerId = this.totalData[this.activeKey].tableData[0].customerId;
        const i = this.totalData[this.activeKey].tableData.findIndex(item => item.customerId !== firstCustomerId);
        if (i !== -1) { this.hideSelectAll = true; }
      }
      // 同一医院下的设备被选中，其他医院不可选
      if (this.actualSelectedIds.length && this.totalData[this.activeKey].tableData.length) {
        const selectedItem = this.totalData[this.activeKey].tableData.find(item => this.actualSelectedIds.find(item2 => item2 === item.deviceId));
        if (selectedItem) {
          this.totalData[this.activeKey].tableData.map(item => {
            if (item.customerId !== selectedItem.customerId) {
              item.disabled = true;
            } else {
              item.disabled = false;
            }
          });
        }
      }
    },

    tableScroll () {
      let target = this.$refs.tableRef.$el.querySelector('.ant-table-body');

      const scrollHeight = target.scrollHeight - target.scrollTop;
      const clientHeight = target.clientHeight;
      if (scrollHeight === 0 && clientHeight === 0) {
        this.totalData[this.activeKey].page.pageNum = 1;
      } else {
        if (scrollHeight < clientHeight + 5) {
          if (this.totalData[this.activeKey].tableData.length < this.totalData[this.activeKey].total) {
            this.totalData[this.activeKey].page.pageNum += 1;

            this.loadData();
          }
        }
      }
    },
    handleConfirm () {
      this.$emit('handleData', this.selectedXnTable);
    },
  },
  created () {
    this.loadData();
  },
  mounted () {
    this.$refs.tableRef.$el
      .querySelector('.ant-table-body')
      .addEventListener('scroll', this.tableScroll, {
        passive: true,
      });
  },
  beforeDestroy () {
    this.$refs.tableRef.$el
      .querySelector('.ant-table-body')
      .removeEventListener('scroll', this.tableScroll);
  },
};
</script>
<style lang="scss" scoped>
/deep/ .ant-table-thead {
  &>tr>th {
    padding: 9px 16px;
    font-weight: bold;
    background-color: #F2F4F7;
  }
}

/deep/ .ant-table-tbody {
  tr {
    background-color: #fff;

    &>td {
      padding: 9px 16px;
    }
  }
}

/deep/ .ant-form-item {
  margin-bottom: 12px;
}
</style>
